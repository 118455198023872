import React from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";
import PricePill from "../../Pills/PricePill";

interface PriceCardProps {
  price: string;
  onClick?: (a: any) => void;
}

const PriceCard: React.FC<PriceCardProps> = ({ price, onClick }) => {
  return (
    <div id="price-card-wrapper">
      {/* <PricePill price={`${price}`} /> */}

      <Button
        children={"Instant Book"}
        buttonStyle="primary"
        onClick={onClick}
      />
    </div>
  );
};

export default PriceCard;

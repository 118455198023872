import { createBrowserRouter } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ReservesDashboardPage from "../pages/Dashboard/Reserves";
import DetailPage from "../pages/Detail";
import BrokerYachtListPage from "../pages/BrokerYachtList";
import PageNotFoundPage from "../pages/PageNotFound";
import MainLayout from "../components/MainLayout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/yacht/:brokerName/:yachtName",
        element: <DetailPage />,
      },

      {
        path: "/broker/:brokerName",
        element: <BrokerYachtListPage />,
      },
      {
        path: "*",
        element: <PageNotFoundPage />,
      },
    ],
  },
]);

export default router;

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/reset.scss";
import "./assets/scss/var.scss";
import "./assets/scss/global.scss";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { initializeApp } from "firebase/app";
import AppProvider from "./providers";
import { Helmet, HelmetProvider } from "react-helmet-async";

const firebaseConfig = {
  // apiKey: "AIzaSyAfIsUNTRTd_73ETgdGlv07eHDhO7lC5nA",
  authDomain: "yacht-charter-429123.firebaseapp.com",
  projectId: "yacht-charter-429123",
  // storageBucket: "yacht-charter-429123.appspot.com",
  // messagingSenderId: "464319701825",
  // appId: "1:464319701825:web:7fcf84b8f30f0ce4bd37c0"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>
);

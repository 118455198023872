import React, { createContext, ReactNode, useState } from "react";
import { YachtBroker } from "../@types/yachtBrokers";

interface SelectYachtProps {
  selectYacht: YachtBroker | undefined;
  setSelectYacht: (yacht: YachtBroker | undefined) => void;
}

interface SelectYachtProviderProps {
  children: ReactNode;
}

export const SelectYachtContext = createContext({} as SelectYachtProps);

export const SelectYachtProvider: React.FC<SelectYachtProviderProps> = ({
  children,
}) => {
  const [selectYacht, setSelectYacht] = useState<YachtBroker | undefined>(
    undefined
  );

  return (
    <SelectYachtContext.Provider value={{ selectYacht, setSelectYacht }}>
      {children}
    </SelectYachtContext.Provider>
  );
};

export const textCapitalizeArray = (text?: string[]): string[] =>
  text?.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ) || [];

export const textCapitalize = (text: string | undefined): string => {
  if (text) {
    let words = text?.replaceAll("_", " ").split(" ");

    let capWords = words.map(
      (word) =>
        word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
    );
    return capWords.join(" ");
  } else {
    return "";
  }
};

import React, { useEffect, useState } from "react";
import "./styles.scss";
import CloseIcon from "../../../assets/icons/bx-x.svg";
import InputText from "../../Inputs/InputText";
import Button from "../../Buttons/Button";
import { FormYachtObj } from "../../../@types/formYachtObj";
import InputMessage from "../../Inputs/InputMessage";
import emailjs from "emailjs-com";
import SimpleSelect from "../../Selects/SimpleSelect";
import codesMock from "../../../mocks/countryCodes.json";
import { setupPhoneNumberInput } from "../../../utils/masks";
import { CountryCode } from "libphonenumber-js";
import { sendEmailContact } from "../../../api/contactUs/sendEmailContact";

interface ContactFormProps {
  formInformationCallback: (obj: FormYachtObj) => void;
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactForm: React.FC<ContactFormProps> = (props) => {
  const { formInformationCallback, isOpenModal, setIsOpenModal } = props;
  const [userName, setUserName] = useState("");
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [userMessageWarning, setUserMessageWarning] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [userPhoneWarning, setUserPhoneWarning] = useState(false);
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState("" as string);

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );

      setUserPhone(formattedNumber);

      setCountryCode(codeCountry.countryCode);
      // setUserPhone(`${e.replace(/\D/g, "")}`);
    } else {
      setUserPhone(e);
      setUserPhone(e.replace(/\D/g, ""));
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpenModal]);

  const submitFormHandler = async () => {
    if (!userName) {
      setUserNameWarning(true);
      setTimeout(() => setUserNameWarning(false), 3000);
      return;
    }

    if (!userEmail) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (userEmail.indexOf("@") === -1 || userEmail.indexOf(".com") === -1) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (!userMessage) {
      setUserMessageWarning(true);
      setTimeout(() => setUserMessageWarning(false), 3000);
      return;
    }

    const templateParams = {
      user_name: userName,
      user_email: userEmail,
      user_phone: userPhone,
      message: userMessage,
      to: userEmail,
    };

    const response = await sendEmailContact(templateParams);

    closeModalHandler();
  };

  const closeModalHandler = () => {
    setUserName("");
    setUserEmail("");

    setIsOpenModal(false);
  };

  const countryCodeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPhoneCode(e.target.value);
  };

  return (
    <>
      {isOpenModal && <div className="modal-overlay-list" />}
      <div id="contact-form" className={isOpenModal ? "show-form" : ""}>
        <div className="header-form">
          <h3 className="header-form-title">Contact Us</h3>
          <p className="header-form-subtitle">
            Fill out the details below so we can assist you better.{" "}
          </p>
        </div>
        <div className="body-form">
          <InputText
            type="text"
            value={userName}
            onChangeInput={setUserName}
            label="Name"
            isWarning={userNameWarning}
          />
          <InputText
            type="email"
            value={userEmail}
            onChangeInput={setUserEmail}
            label="Email"
            isWarning={userEmailWarning}
          />
          <p className="label-input-text">Phone</p>
          <div className="grid-column-phone">
            <SimpleSelect
              options={codesMock.map((code) => {
                return {
                  label: `${code.flag} ${code.code} ${code.countryCode}`,
                  value: JSON.stringify(code),
                };
              })}
              onChange={countryCodeHandler}
              value={phoneCode}
              defaultValueText={"Select"}
            />
            <InputText
              value={userPhone}
              onChangeInput={inputPhoneHandler}
              placeholder="Phone number"
            />
          </div>
          <InputMessage
            value={userMessage}
            onChangeInput={setUserMessage}
            label="Message"
            isWarning={userMessageWarning}
          />

          <div className="form-footer">
            <Button onClick={submitFormHandler} buttonStyle="primary">
              Submit
            </Button>
            <Button onClick={closeModalHandler} buttonStyle="quaternary">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

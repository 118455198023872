import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import TitleParagraph from "../../components/Texts/TitleParagraph";
import { useNavigate } from "react-router-dom";
import FeaturedCarousel from "../../components/Carousels/FeaturedCarousel";
import nxVideo from "../../assets/videos/video-destaque-Nx400ht.mp4";
import Button from "../../components/Buttons/Button";
import { initGA, logEvent, logPageView } from "../../utils/analytics";
import YachtCard from "../../components/Cards/YachtCard";
import { yachtsBrokers } from "../../mocks/yachtsBrokers";
import boat1 from "../../assets/images/sunseeker/sunseeker04.png";
import { YachtBroker } from "../../@types/yachtBrokers";
import { SelectYachtContext } from "../../contexts/selectYachtContext";
import logo from "../../assets/logos/yacht_charter_logo_blue.svg";
import boatsPhelipe from "../../mocks/phelipe";
import { Helmet } from "react-helmet-async";
import metaImage from "../../assets/images/yachtcharater-meta.png";
import MetaTags from "../../components/MetaTags";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { setSelectYacht } = useContext(SelectYachtContext);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [windowSizeHeight, setWindowSizeHeight] = useState(window.innerHeight);

  useEffect(() => {
    initGA();

    const captureUTMParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const allParams: Record<string, string> = {};

      urlParams.forEach((value, key) => {
        allParams[key] = value;
      });

      if (Object.keys(allParams).length > 0) {
        localStorage.setItem("urlParams", JSON.stringify(allParams));

        logEvent("URL Params", "Capture", JSON.stringify(allParams));
      }
    };

    const cleanURL = () => {
      if (window.location.search) {
        const url = new URL(window.location.href);
        url.search = "";
        window.history.replaceState({}, document.title, url.toString());
      }
    };

    captureUTMParams();
    cleanURL();
    logPageView();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setWindowSizeHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInstantBook = () => {
    localStorage.setItem("scrollTarget", "#yacht-booking-form");
    setSelectYacht(
      yachtsBrokers.find((broker) => broker.broker === "Phelipe Mestre")
    );
    navigate(`/yacht/phelipe-mestre/${boatsPhelipe[0].routeName}`);
  };

  const yachtDetailsHandler = (yacht: YachtBroker) => {
    const broker = yacht.broker
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll("’", " ")
      .trim();

    setSelectYacht(yacht);

    navigate(`/yacht/${broker}/${yacht.routeName}`);
  };

  return (
    <>
      <div id="landing-page">
        <LayoutDefault className="landing-page">
          <FeaturedCarousel
            videoBanner
            //imagesCarousel={imgCarousel}
            secondaryButton={windowSizeHeight > 400}
            infoText={{
              title: boatsPhelipe[0].texts.title,
              subTitle: boatsPhelipe[0].texts.subTitle,
              buttonText: "More Details",
              buttonClick: () =>
                navigate(`/yacht/phelipe-mestre/${boatsPhelipe[0].routeName}`),
              secondaryButtonText: "Instant Book",
              secondaryButtonClick: handleInstantBook,
            }}
          />
          <div className="boat-info-wrapper">
            <div className="title-paragraph-wrapper">
              <img src={logo} />
              <TitleParagraph
                title={"The Experience of a<br>Lifetime in Miami"}
                paragraph={`Whether you’re hosting a corporate event, enjoying a 
								family outing, or celebrating a special occasion, we’ve got you 
								covered with an unforgettable yacht experience in Miami.`}
                textAlign="center"
                paragraphColor="gray"
              />
            </div>
            <div className="yacht-cards-wrapper">
              {yachtsBrokers
                .sort((a, b) => {
                  if (a.broker === "Phelipe Mestre") return -1;
                  if (b.broker === "Phelipe Mestre") return 1;
                  return 0;
                })
                .map((yacht, index) => (
                  <YachtCard
                    key={index}
                    specialOffer={yacht.broker === "Phelipe Mestre"}
                    startPrice={yacht.base_price}
                    yachtName={yacht.name}
                    // yachtSpecification={yacht.model}
                    yachtImage={yacht.image}
                    buttonClick={() =>
                      yachtDetailsHandler(yacht as YachtBroker)
                    }
                  />
                ))}
            </div>

            {/* <div className="instant-book-button-container">
            <Button
              buttonStyle="primary"
              children="More Details"
              onClick={() => navigate(`/yacht/${boats[0].routeName}`)}
            />
          </div> */}
            {/* <iframe
            className="video-player"
            src="https://www.youtube.com/embed/cVBMwjWEg_4?si=smC2FjuUaQZSUPdi"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe> */}
            <video className="video-player" autoPlay loop muted playsInline>
              <source src={nxVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <div className="cards-row">
            {boats.map((card, index) => (
              <Card
                key={index}
                title={card.name}
                subTitle={card.specification}
                price={card.prices.card}
                vehicleImg={card.vehicleImg}
                showComingSoon={card.showComingSoon}
                onClick={() => navigate(`/yacht/${card.routeName}`)}
              />
            ))}
          </div> */}
          </div>
        </LayoutDefault>
      </div>
    </>
  );
};

export default LandingPage;

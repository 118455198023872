import AdrianaJuhaszAvatar from "../assets/images/brokers/AdrianaJuhasz/Adriana-Juhasz.jpeg";
import NickMarinAvatar from "../assets/images/brokers/NikMarin/nik_profile-2.jpeg";
import PhelipeAvatar from "../assets/images/brokers/phelipemestreperes.jpg";

type BrokerInfo = {
  avatar: string;
  name: string;
  subText: string;
  email: string;
};

export const brokersList: BrokerInfo[] = [
  {
    name: "Adriana Juhasz",
    avatar: AdrianaJuhaszAvatar,
    subText: "Yacht Broker",
    email: "adrianayacht@gmail.com",
  },
  {
    name: "Nik Marin",
    avatar: NickMarinAvatar,
    subText: "Yacht Broker",
    email: "premiernightlifegroupllc@gmail.com",
  },
  {
    name: "Phelipe Mestre",
    avatar: PhelipeAvatar,
    subText: "Yacht Broker",
    email: "phe.mestre@gmail.com",
  },
  {
    name: "Chris",
    avatar: "",
    subText: "Yacht Broker",
    email: "",
  },
];

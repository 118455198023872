import React from "react";
import "./styles.scss";
import SpecsPill from "../../Pills/SpecsPill";
import ListSpec from "../../ListItem/ListSpec";
import { YachtBroker } from "../../../@types/yachtBrokers";

interface SpecificationsCardProps {
  boat?: YachtBroker;
}

type ISpecs = {
  specTitle: string;
  subSpec: string;
  mainSpec: string;
};

const SpecificationsCard: React.FC<SpecificationsCardProps> = (props) => {
  const { boat } = props;

  const feetToMeters = (feet?: string) => {
    if (!feet) return "-";
    const match = feet.match(/(\d+)’(?:\s*(\d+)")?/);

    if (!match) return "-";

    const feetValue = Number(match[1]);
    const inchesValue = match[2] ? Number(match[2]) : 0;

    const totalMeters = feetValue * 0.3048 + inchesValue * 0.0254;

    return totalMeters.toFixed(2) + " m";
  };

  const boatSpecs = [
    {
      specTitle: "Length",
      subSpec: feetToMeters(boat?.length),
      mainSpec: boat?.length,
    },
    {
      specTitle: "Beam",
      subSpec: feetToMeters(boat?.beam),
      mainSpec: boat?.beam,
    },
    {
      specTitle: "Draft",
      subSpec: feetToMeters(boat?.draft),
      mainSpec: boat?.draft,
    },
    {
      specTitle: "Cruising Speed",
      subSpec: "approx.",
      mainSpec: boat?.cruising_speed,
    },
    {
      specTitle: "Year Built",
      subSpec: "",
      mainSpec: "",
    },
    {
      specTitle: "Builder",
      subSpec: "",
      mainSpec: boat?.make,
    },
    {
      specTitle: "Model",
      subSpec: "",
      mainSpec: boat?.model,
    },
    {
      specTitle: "Class",
      subSpec: "(Power)",
      mainSpec: "Yacht",
    },
  ];

  return (
    <div id="specifications-wrapper">
      <h3 className="disclaimer left">Specifications</h3>
      <div className="specs-highlight">
        {boat?.passengers && (
          <SpecsPill
            fontFamilyTitle="roboto"
            title={"Passengers"}
            spec={`2 - ${boat?.passengers}`}
          />
        )}

        <SpecsPill
          fontFamilyTitle="roboto"
          title={"Crew"}
          spec={`1-${boat?.crew}`}
        />
        <SpecsPill
          fontFamilyTitle="roboto"
          title={"Cabins"}
          spec={boat?.cabins + ""}
        />
      </div>
      {boatSpecs.map((spec, index) => {
        if (!spec.mainSpec) return null;

        return (
          <React.Fragment key={index}>
            <ListSpec
              specTitle={spec.specTitle}
              subSpec={spec.subSpec}
              mainSpec={spec.mainSpec as string}
            />
            {index < boatSpecs.length - 1 && <div className="separator-list" />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SpecificationsCard;

import React from "react";
import "./styles.scss";

interface HrProps {
  hasHorizontalMargin?: boolean;
  className?: string;
}

const Hr: React.FC<HrProps> = (props) => {
  const { hasHorizontalMargin = true, className } = props;

  return (
    <div
      id="separator-default"
      className={`${hasHorizontalMargin ? "horizontal-margin" : ""} ${
        className ?? ""
      }`}
    />
  );
};

export default Hr;

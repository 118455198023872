import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//@ts-ignore
const metaConfig: Record<string, MetaConfig> = {
  "/": {
    title: "Yacht Charter Miami",
    description: "The Experience of a Life Time in Miami",
    image: "/images/yachtcharter-meta.png",
  },
  "/broker/adriana-juhasz": {
    title: "Yacht Charter Miami | Adriana Juhasz",
    description: "The Experience of a Life Time in Miami",
    image: "/images/adriana-meta.png",
  },
  "/broker/nik-marin": {
    title: "Yacht Charter Miami | Nik Marin",
    description: "The Experience of a Life Time in Miami",
    image: "/images/nik-meta.png",
  },
  "/broker/phelipe-mestre": {
    title: "Yacht Charter Miami | Phelipe Mestre",
    description: "The Experience of a Life Time in Miami",
    image: "/images/yachtcharter-meta.png",
  },
  "/broker/chris": {
    title: "Yacht Charter Miami | Chris",
    description: "The Experience of a Life Time in Miami",
    image: "/images/cris-meta.png",
  },
};

const MetaTags = () => {
  const location = useLocation();
  const [meta, setMeta] = useState(metaConfig["/"]);

  useEffect(() => {
    const cleanPathname = location.pathname.split("?")[0];
    setMeta(metaConfig[cleanPathname] || metaConfig["/"]);
    console.log("Updated Metadata for:", cleanPathname);
  }, [location.pathname]);

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={`https://stg.yachtcharter.miami${meta.image}`}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />
      <meta
        property="og:url"
        content={`https://stg.yachtcharter.miami${location.pathname}`}
      />
    </Helmet>
  );
};

export default MetaTags;

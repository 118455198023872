//70 Azimut Dolce
import azimut70dolce_1 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_1.jpg";
import azimut70dolce_2 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_2.jpg";
import azimut70dolce_3 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_3.jpg";
import azimut70dolce_4 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_4.jpg";
import azimut70dolce_5 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_5.jpg";
import azimut70dolce_6 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_6.jpg";
import azimut70dolce_7 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_7.jpg";
import azimut70dolce_8 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_8.jpg";
import azimut70dolce_9 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_9.jpg";
import azimut70dolce_10 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_10.jpg";
import azimut70dolce_11 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_11.jpg";
import azimut70dolce_12 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_12.jpg";
import azimut70dolce_13 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_13.jpg";
import azimut70dolce_14 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_14.jpg";
import azimut70dolce_15 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_15.jpg";
import azimut70dolce_16 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_16.jpg";
import azimut70dolce_17 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_17.jpg";
import azimut70dolce_18 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_18.jpg";
import azimut70dolce_19 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_19.jpg";
import azimut70dolce_20 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_20.jpg";
import azimut70dolce_21 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_21.jpg";
import azimut70dolce_22 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_22.jpg";
import azimut70dolce_23 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_23.jpg";
import azimut70dolce_24 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_24.jpg";
import azimut70dolce_25 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_25.jpg";
import azimut70dolce_26 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_26.jpg";
import azimut70dolce_27 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_27.jpg";
import azimut70dolce_28 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_28.jpg";
import azimut70dolce_29 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_29.jpg";
import azimut70dolce_30 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_30.jpg";
import azimut70dolce_31 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_31.jpg";
import azimut70dolce_32 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_32.jpg";
import azimut70dolce_33 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_33.jpg";
import azimut70dolce_34 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_34.jpg";
import azimut70dolce_35 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_35.jpg";
import azimut70dolce_36 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_36.jpg";
import azimut70dolce_37 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_37.jpg";
import azimut70dolce_38 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_38.jpg";
import azimut70dolce_39 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_39.jpg";
import azimut70dolce_40 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_40.jpg";
import azimut70dolce_41 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_41.jpg";
import azimut70dolce_42 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_42.jpg";
import azimut70dolce_43 from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_43.jpg";

//80 Baia Panther
import baia80panther_1 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_1.jpg";
import baia80panther_2 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_2.jpg";
import baia80panther_3 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_3.jpg";
import baia80panther_4 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_4.jpg";
import baia80panther_5 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_5.jpg";
import baia80panther_6 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_6.jpg";
import baia80panther_7 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_7.jpg";
import baia80panther_8 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_8.jpg";
import baia80panther_9 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_9.jpg";
import baia80panther_10 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_10.jpg";
import baia80panther_11 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_11.jpg";
import baia80panther_12 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_12.jpg";
import baia80panther_13 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_13.jpg";
import baia80panther_14 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_14.jpg";
import baia80panther_15 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_15.jpg";
import baia80panther_16 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_16.jpg";
import baia80panther_17 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_17.jpg";
import baia80panther_18 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_18.jpg";
import baia80panther_19 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_19.jpg";
import baia80panther_20 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_20.jpg";
import baia80panther_21 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_21.jpg";
import baia80panther_22 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_22.jpg";
import baia80panther_23 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_23.jpg";
import baia80panther_24 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_24.jpg";
import baia80panther_25 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_25.jpg";
import baia80panther_26 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_26.jpg";
import baia80panther_27 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_27.jpg";
import baia80panther_28 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_28.jpg";
import baia80panther_29 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_29.jpg";
import baia80panther_30 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_30.jpg";
import baia80panther_31 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_31.jpg";
import baia80panther_32 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_32.jpg";
import baia80panther_33 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_33.jpg";
import baia80panther_34 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_34.jpg";
import baia80panther_35 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_35.jpg";
import baia80panther_36 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_36.jpg";
import baia80panther_37 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_37.jpg";
import baia80panther_38 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_38.jpg";
import baia80panther_39 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_39.jpg";
import baia80panther_40 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_40.jpg";
import baia80panther_41 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_41.jpg";
import baia80panther_42 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_42.jpg";
import baia80panther_43 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_43.jpg";
import baia80panther_44 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_44.jpg";
import baia80panther_45 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_45.jpg";
import baia80panther_46 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_46.jpg";
import baia80panther_47 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_47.jpg";
import baia80panther_48 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_48.jpg";
import baia80panther_49 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_49.jpg";
import baia80panther_50 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_50.jpg";
import baia80panther_51 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_51.jpg";
import baia80panther_52 from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_52.jpg";

//90 Azimut Dydream
import azimut90dydream_1 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_1.jpg";
import azimut90dydream_2 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_2.jpg";
import azimut90dydream_3 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_3.jpg";
import azimut90dydream_4 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_4.jpg";
import azimut90dydream_5 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_5.jpg";
import azimut90dydream_6 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_6.jpg";
import azimut90dydream_7 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_7.jpg";
import azimut90dydream_8 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_8.jpg";
import azimut90dydream_9 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_9.jpg";
import azimut90dydream_10 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_10.jpg";
import azimut90dydream_11 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_11.jpg";
import azimut90dydream_12 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_12.jpg";
import azimut90dydream_13 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_13.jpg";
import azimut90dydream_14 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_14.jpg";
import azimut90dydream_15 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_15.jpg";
import azimut90dydream_16 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_16.jpg";
import azimut90dydream_17 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_17.jpg";
import azimut90dydream_18 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_18.jpg";
import azimut90dydream_19 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_19.jpg";
import azimut90dydream_20 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_20.jpg";
import azimut90dydream_21 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_21.jpg";
import azimut90dydream_22 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_22.jpg";
import azimut90dydream_23 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_23.jpg";
import azimut90dydream_24 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_24.jpg";
import azimut90dydream_25 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_25.jpg";
import azimut90dydream_26 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_26.jpg";
import azimut90dydream_27 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_27.jpg";
import azimut90dydream_28 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_28.jpg";
import azimut90dydream_29 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_29.jpg";
import azimut90dydream_30 from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_30.jpg";

//90 Jerico
import pershing94jerico_1 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_1.jpg";
import pershing94jerico_2 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_2.jpg";
import pershing94jerico_3 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_3.jpg";
import pershing94jerico_4 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_4.jpg";
import pershing94jerico_5 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_5.jpg";
import pershing94jerico_6 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_6.jpg";
import pershing94jerico_7 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_7.jpg";
import pershing94jerico_8 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_8.jpg";
import pershing94jerico_9 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_9.jpg";
import pershing94jerico_10 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_10.jpg";
import pershing94jerico_11 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_11.jpg";
import pershing94jerico_12 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_12.jpg";
import pershing94jerico_13 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_13.jpg";
import pershing94jerico_14 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_14.jpg";
import pershing94jerico_15 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_15.jpg";
import pershing94jerico_16 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_16.jpg";
import pershing94jerico_17 from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_17.jpg";

//105 Azimut Daniela
import azimut105daniela_1 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_1.jpg";
import azimut105daniela_2 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_2.jpg";
import azimut105daniela_3 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_3.jpg";
import azimut105daniela_4 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_4.jpg";
import azimut105daniela_5 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_5.jpg";
import azimut105daniela_6 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_6.jpg";
import azimut105daniela_7 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_7.jpg";
import azimut105daniela_8 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_8.jpg";
import azimut105daniela_9 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_9.jpg";
import azimut105daniela_10 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_10.jpg";
import azimut105daniela_11 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_11.jpg";
import azimut105daniela_12 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_12.jpg";
import azimut105daniela_13 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_13.jpg";
import azimut105daniela_14 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_14.jpg";
import azimut105daniela_15 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_15.jpg";
import azimut105daniela_16 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_16.jpg";
import azimut105daniela_17 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_17.jpg";
import azimut105daniela_18 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_18.jpg";
import azimut105daniela_19 from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_19.jpg";

//120 Azimut Rex
import azimut120rex_1 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_1.jpg";
import azimut120rex_2 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_2.jpg";
import azimut120rex_3 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_3.jpg";
import azimut120rex_4 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_4.jpg";
import azimut120rex_5 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_5.jpg";
import azimut120rex_6 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_6.jpg";
import azimut120rex_7 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_7.jpg";
import azimut120rex_8 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_8.jpg";
import azimut120rex_9 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_9.jpg";
import azimut120rex_10 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_10.jpg";
import azimut120rex_11 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_11.jpg";
import azimut120rex_12 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_12.jpg";
import azimut120rex_13 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_13.jpg";
import azimut120rex_14 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_14.jpg";
import azimut120rex_15 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_15.jpg";
import azimut120rex_16 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_16.jpg";
import azimut120rex_17 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_17.jpg";
import azimut120rex_18 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_18.jpg";
import azimut120rex_19 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_19.jpg";
import azimut120rex_20 from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_20.jpg";

const boats = [
  {
    name: "Azimut 70'",
    specification: "La Dolce Vita",
    routeName: "70-azimut-ladolcevita",
    showComingSoon: false,
    vehicleImg: "",
    texts: {
      title: "Azimut 70'<br>La Dolce Vita",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning Azimut 70' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    images: [
      { id: "0", img: azimut70dolce_1, type: "photo" },
      { id: "1", img: azimut70dolce_2, type: "photo" },
      { id: "2", img: azimut70dolce_3, type: "photo" },
      { id: "3", img: azimut70dolce_4, type: "photo" },
      { id: "4", img: azimut70dolce_5, type: "photo" },
      { id: "5", img: azimut70dolce_6, type: "photo" },
      { id: "6", img: azimut70dolce_7, type: "photo" },
      { id: "7", img: azimut70dolce_8, type: "photo" },
      { id: "8", img: azimut70dolce_9, type: "photo" },
      { id: "9", img: azimut70dolce_10, type: "photo" },
      { id: "10", img: azimut70dolce_11, type: "photo" },
      { id: "11", img: azimut70dolce_12, type: "photo" },
      { id: "12", img: azimut70dolce_13, type: "photo" },
      { id: "13", img: azimut70dolce_14, type: "photo" },
      { id: "14", img: azimut70dolce_15, type: "photo" },
      { id: "15", img: azimut70dolce_16, type: "photo" },
      { id: "16", img: azimut70dolce_17, type: "photo" },
      { id: "17", img: azimut70dolce_18, type: "photo" },
      { id: "18", img: azimut70dolce_19, type: "photo" },
      { id: "19", img: azimut70dolce_20, type: "photo" },
      { id: "20", img: azimut70dolce_21, type: "photo" },
      { id: "21", img: azimut70dolce_22, type: "photo" },
      { id: "22", img: azimut70dolce_23, type: "photo" },
      { id: "23", img: azimut70dolce_24, type: "photo" },
      { id: "24", img: azimut70dolce_25, type: "photo" },
      { id: "25", img: azimut70dolce_26, type: "photo" },
      { id: "26", img: azimut70dolce_27, type: "photo" },
      { id: "27", img: azimut70dolce_28, type: "photo" },
      { id: "28", img: azimut70dolce_29, type: "photo" },
      { id: "29", img: azimut70dolce_30, type: "photo" },
      { id: "30", img: azimut70dolce_31, type: "photo" },
      { id: "31", img: azimut70dolce_32, type: "photo" },
      { id: "32", img: azimut70dolce_33, type: "photo" },
      { id: "33", img: azimut70dolce_34, type: "photo" },
      { id: "34", img: azimut70dolce_35, type: "photo" },
      { id: "35", img: azimut70dolce_36, type: "photo" },
      { id: "36", img: azimut70dolce_37, type: "photo" },
      { id: "37", img: azimut70dolce_38, type: "photo" },
      { id: "38", img: azimut70dolce_39, type: "photo" },
      { id: "39", img: azimut70dolce_40, type: "photo" },
      { id: "40", img: azimut70dolce_41, type: "photo" },
      { id: "41", img: azimut70dolce_42, type: "photo" },
      { id: "42", img: azimut70dolce_43, type: "photo" },
    ],
  },
  {
    name: "Baia 80'",
    specification: "Panther",
    texts: {
      title: "Baia 80'<br>Panther",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning Baia 80' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    routeName: "80-baia-panther",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: baia80panther_1, type: "photo" },
      { id: "1", img: baia80panther_2, type: "photo" },
      { id: "2", img: baia80panther_3, type: "photo" },
      { id: "3", img: baia80panther_4, type: "photo" },
      { id: "4", img: baia80panther_5, type: "photo" },
      { id: "5", img: baia80panther_6, type: "photo" },
      { id: "6", img: baia80panther_7, type: "photo" },
      { id: "7", img: baia80panther_8, type: "photo" },
      { id: "8", img: baia80panther_9, type: "photo" },
      { id: "9", img: baia80panther_10, type: "photo" },
      { id: "10", img: baia80panther_11, type: "photo" },
      { id: "11", img: baia80panther_12, type: "photo" },
      { id: "12", img: baia80panther_13, type: "photo" },
      { id: "13", img: baia80panther_14, type: "photo" },
      { id: "14", img: baia80panther_15, type: "photo" },
      { id: "15", img: baia80panther_16, type: "photo" },
      { id: "16", img: baia80panther_17, type: "photo" },
      { id: "17", img: baia80panther_18, type: "photo" },
      { id: "18", img: baia80panther_19, type: "photo" },
      { id: "19", img: baia80panther_20, type: "photo" },
      { id: "20", img: baia80panther_21, type: "photo" },
      { id: "21", img: baia80panther_22, type: "photo" },
      { id: "22", img: baia80panther_23, type: "photo" },
      { id: "23", img: baia80panther_24, type: "photo" },
      { id: "24", img: baia80panther_25, type: "photo" },
      { id: "25", img: baia80panther_26, type: "photo" },
      { id: "26", img: baia80panther_27, type: "photo" },
      { id: "27", img: baia80panther_28, type: "photo" },
      { id: "28", img: baia80panther_29, type: "photo" },
      { id: "29", img: baia80panther_30, type: "photo" },
      { id: "30", img: baia80panther_31, type: "photo" },
      { id: "31", img: baia80panther_32, type: "photo" },
      { id: "32", img: baia80panther_33, type: "photo" },
      { id: "33", img: baia80panther_34, type: "photo" },
      { id: "34", img: baia80panther_35, type: "photo" },
      { id: "35", img: baia80panther_36, type: "photo" },
      { id: "36", img: baia80panther_37, type: "photo" },
      { id: "37", img: baia80panther_38, type: "photo" },
      { id: "38", img: baia80panther_39, type: "photo" },
      { id: "39", img: baia80panther_40, type: "photo" },
      { id: "40", img: baia80panther_41, type: "photo" },
      { id: "41", img: baia80panther_42, type: "photo" },
      { id: "42", img: baia80panther_43, type: "photo" },
      { id: "43", img: baia80panther_44, type: "photo" },
      { id: "44", img: baia80panther_45, type: "photo" },
      { id: "45", img: baia80panther_46, type: "photo" },
      { id: "46", img: baia80panther_47, type: "photo" },
      { id: "47", img: baia80panther_48, type: "photo" },
      { id: "48", img: baia80panther_49, type: "photo" },
      { id: "49", img: baia80panther_50, type: "photo" },
      { id: "50", img: baia80panther_51, type: "photo" },
      { id: "51", img: baia80panther_52, type: "photo" },
    ],
  },
  {
    name: "Azimut 90'",
    specification: "Daydreamin",
    texts: {
      title: "Azimut 0'<br>Panther",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning Azimut 90' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    routeName: "90-azimut-daydream",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: azimut90dydream_1, type: "photo" },
      { id: "1", img: azimut90dydream_2, type: "photo" },
      { id: "2", img: azimut90dydream_3, type: "photo" },
      { id: "3", img: azimut90dydream_4, type: "photo" },
      { id: "4", img: azimut90dydream_5, type: "photo" },
      { id: "5", img: azimut90dydream_6, type: "photo" },
      { id: "6", img: azimut90dydream_7, type: "photo" },
      { id: "7", img: azimut90dydream_8, type: "photo" },
      { id: "8", img: azimut90dydream_9, type: "photo" },
      { id: "9", img: azimut90dydream_10, type: "photo" },
      { id: "10", img: azimut90dydream_11, type: "photo" },
      { id: "11", img: azimut90dydream_12, type: "photo" },
      { id: "12", img: azimut90dydream_13, type: "photo" },
      { id: "13", img: azimut90dydream_14, type: "photo" },
      { id: "14", img: azimut90dydream_15, type: "photo" },
      { id: "15", img: azimut90dydream_16, type: "photo" },
      { id: "16", img: azimut90dydream_17, type: "photo" },
      { id: "17", img: azimut90dydream_18, type: "photo" },
      { id: "18", img: azimut90dydream_19, type: "photo" },
      { id: "19", img: azimut90dydream_20, type: "photo" },
      { id: "20", img: azimut90dydream_21, type: "photo" },
      { id: "21", img: azimut90dydream_22, type: "photo" },
      { id: "22", img: azimut90dydream_23, type: "photo" },
      { id: "23", img: azimut90dydream_24, type: "photo" },
      { id: "24", img: azimut90dydream_25, type: "photo" },
      { id: "25", img: azimut90dydream_26, type: "photo" },
      { id: "26", img: azimut90dydream_27, type: "photo" },
      { id: "27", img: azimut90dydream_28, type: "photo" },
      { id: "28", img: azimut90dydream_29, type: "photo" },
      { id: "29", img: azimut90dydream_30, type: "photo" },
    ],
  },
  {
    name: "Pershing 94'",
    specification: "Jerico",
    texts: {
      title: "Pershing 94'<br>Jerico",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning Pershing 94' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    routeName: "94-pershing-jerico",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: pershing94jerico_1, type: "photo" },
      { id: "1", img: pershing94jerico_2, type: "photo" },
      { id: "2", img: pershing94jerico_3, type: "photo" },
      { id: "3", img: pershing94jerico_4, type: "photo" },
      { id: "4", img: pershing94jerico_5, type: "photo" },
      { id: "5", img: pershing94jerico_6, type: "photo" },
      { id: "6", img: pershing94jerico_7, type: "photo" },
      { id: "7", img: pershing94jerico_8, type: "photo" },
      { id: "8", img: pershing94jerico_9, type: "photo" },
      { id: "9", img: pershing94jerico_10, type: "photo" },
      { id: "10", img: pershing94jerico_11, type: "photo" },
      { id: "11", img: pershing94jerico_12, type: "photo" },
      { id: "12", img: pershing94jerico_13, type: "photo" },
      { id: "13", img: pershing94jerico_14, type: "photo" },
      { id: "14", img: pershing94jerico_15, type: "photo" },
      { id: "15", img: pershing94jerico_16, type: "photo" },
      { id: "16", img: pershing94jerico_17, type: "photo" },
    ],
  },
  {
    name: "Azimut 105'",
    specification: "Daniela",
    texts: {
      title: "Azimut 105'<br>Daniela",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning Azimut 105' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    routeName: "105-azimut-daniela",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: azimut105daniela_1, type: "photo" },
      { id: "1", img: azimut105daniela_2, type: "photo" },
      { id: "2", img: azimut105daniela_3, type: "photo" },
      { id: "3", img: azimut105daniela_4, type: "photo" },
      { id: "4", img: azimut105daniela_5, type: "photo" },
      { id: "5", img: azimut105daniela_6, type: "photo" },
      { id: "6", img: azimut105daniela_7, type: "photo" },
      { id: "7", img: azimut105daniela_8, type: "photo" },
      { id: "8", img: azimut105daniela_9, type: "photo" },
      { id: "9", img: azimut105daniela_10, type: "photo" },
      { id: "10", img: azimut105daniela_11, type: "photo" },
      { id: "11", img: azimut105daniela_12, type: "photo" },
      { id: "12", img: azimut105daniela_13, type: "photo" },
      { id: "13", img: azimut105daniela_14, type: "photo" },
      { id: "14", img: azimut105daniela_15, type: "photo" },
      { id: "15", img: azimut105daniela_16, type: "photo" },
      { id: "16", img: azimut105daniela_17, type: "photo" },
      { id: "17", img: azimut105daniela_18, type: "photo" },
      { id: "18", img: azimut105daniela_19, type: "photo" },
    ],
  },
  {
    name: "Azimut 120'",
    specification: "Rex",
    texts: {
      title: "Azimut 120'<br>Rex",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family outing, or celebrating a special occasion, we’ve got you covered with an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
								 stunning Azimut 120' Yacht, exclusively offered by Yacht 
								 Charter Miami. Our bespoke yacht charters promise an
								 unparalleled luxury experience.`,
    },
    routeName: "120-azimut-rex",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: azimut120rex_1, type: "photo" },
      { id: "1", img: azimut120rex_2, type: "photo" },
      { id: "2", img: azimut120rex_3, type: "photo" },
      { id: "3", img: azimut120rex_4, type: "photo" },
      { id: "4", img: azimut120rex_5, type: "photo" },
      { id: "5", img: azimut120rex_6, type: "photo" },
      { id: "6", img: azimut120rex_7, type: "photo" },
      { id: "7", img: azimut120rex_8, type: "photo" },
      { id: "8", img: azimut120rex_9, type: "photo" },
      { id: "9", img: azimut120rex_10, type: "photo" },
      { id: "10", img: azimut120rex_11, type: "photo" },
      { id: "11", img: azimut120rex_12, type: "photo" },
      { id: "12", img: azimut120rex_13, type: "photo" },
      { id: "13", img: azimut120rex_14, type: "photo" },
      { id: "14", img: azimut120rex_15, type: "photo" },
      { id: "15", img: azimut120rex_16, type: "photo" },
      { id: "16", img: azimut120rex_17, type: "photo" },
      { id: "17", img: azimut120rex_18, type: "photo" },
      { id: "18", img: azimut120rex_19, type: "photo" },
      { id: "19", img: azimut120rex_20, type: "photo" },
    ],
  },
];

export default boats;

import axios from "axios";
import { apiServices } from "../apiServices";

export type SendEmailReserve = {
  boat: string;
  end_date: string | undefined;
  google_calendar_url: string;
  hour: string | undefined;
  name: string;
  number_passenger: number;
  start_date: string;
  to: string;
  startTime: string;
  // bookingValue: string;
  // captainValue: string;
  // totalValue: string;
  discount: string | undefined;
};

export const sendEmailReserve = async (data: SendEmailReserve) => {
  const req = await axios({
    method: "post",
    url: `${apiServices()}/send-booking-email`,
    data,
  });

  return { status: req.status, res: req.data };
};

import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import TitleParagraph from "../../components/Texts/TitleParagraph";
import CarouselDefault from "../../components/Carousels/CarouselDefault";
import SlideCarousel from "../../components/Carousels/SlideCarousel";
import Details from "../../components/Details";
import PriceCard from "../../components/Cards/PriceCard";
import SpecificationsCard from "../../components/Cards/SpecificationsCard";
import DetailsCaption from "../../components/Details/DetailsCaption";
import { FormYachtObj } from "../../@types/formYachtObj";
import logo from "../../assets/logos/yacht-charter-logo.png";
import NXHorizon from "../../assets/images/nx-horizon-40.png";
import YachtBookingForm from "../../components/Forms/YachtBookingForm";
import {
  SendEmailReserve,
  sendEmailReserve,
} from "../../api/reserves/sendEmailReserve";
import {
  EventDetails,
  formatGoogleCalendarUrl,
} from "../../utils/googleCalendarUrlFormatter";
import {
  SendEmailReserveInfo,
  sendEmailReserveInfo,
} from "../../api/reserves/sendEmailReserveInfo";
import { initGA, logEvent, logPageView } from "../../utils/analytics";
import { getReservesFromCalendar } from "../../api/reserves/getReservesFromCalendar";
import { ICalendarEvent } from "../../@types/calendarEvent";
import { formatDateTimeObj } from "../../utils/formatDateTimeObj";
import { useNavigate, useParams } from "react-router-dom";
import { brokersList } from "../../mocks/brokersDetails";
import { SelectYachtContext } from "../../contexts/selectYachtContext";
import { textCapitalizeArray } from "../../utils/textCapitalize";
import boatsAdriana from "../../mocks/adriana-juhasz";
import boatsNik from "../../mocks/nikMarin";
import boatsPhelipe from "../../mocks/phelipe";
import boatsChris from "../../mocks/chris";
import { IYachtDetails } from "../../@types/yachtDetails";
import { YachtBroker } from "../../@types/yachtBrokers";
import { yachtsBrokers } from "../../mocks/yachtsBrokers";

interface IExpDateTime {
  expDateStart: string;
  expDateEnd: string;
  expHourMinutesStart: string;
  expHourMinutesEnd: string;
}

type BrokerInfo = {
  avatar: string;
  name: string;
  subText: string;
  email: string;
};
const DetailPage: React.FC = () => {
  const [activeImageId, setActiveImageId] = useState<string | undefined>(
    undefined
  );
  const [openForm, setOpenForm] = useState(false);
  const [showPriceCard, setShowPriceCard] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [windowSizeHeight, setWindowSizeHeight] = useState(window.innerHeight);
  const [expObject, setExpObject] = useState<IExpDateTime[] | []>([]);
  const [brokerInfo, setBrokerInfo] = useState<BrokerInfo | undefined>(
    undefined
  );
  const [yachtDetails, setYachtDetails] = useState<IYachtDetails>(
    {} as IYachtDetails
  );
  const [selectedBoatSpecifications, setSelectedBoatSpecifications] = useState<
    YachtBroker | undefined
  >(undefined);

  const params = useParams();
  const navigate = useNavigate();
  console.log(windowSizeHeight);

  useEffect(() => {
    if (openForm && windowSize < 768) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [openForm]);

  useEffect(() => {
    if (params.brokerName && params.yachtName) {
      const brokerNameChange = textCapitalizeArray(
        params?.brokerName.split("-")
      )?.join(" ");

      const listNamesBrokers = brokersList.find(
        (broker) => broker.name === brokerNameChange
      );

      if (!listNamesBrokers) {
        return navigate("/");
      }
      setSelectedBoatSpecifications(
        yachtsBrokers.find((spec) => spec.routeName === params.yachtName)
      );

      setBrokerInfo(
        brokersList.find((broker) => broker.name === brokerNameChange)
      );

      let selectedBoat: IYachtDetails | undefined;

      switch (params.brokerName) {
        case "nik-marin":
          selectedBoat = boatsNik.find(
            (boat) => boat.routeName === params.yachtName
          );
          if (selectedBoat) setYachtDetails(selectedBoat);

          break;
        case "chris":
          selectedBoat = boatsChris.find(
            (boat) => boat.routeName === params.yachtName
          );
          if (selectedBoat) setYachtDetails(selectedBoat);

          break;
        case "adriana-juhasz":
          selectedBoat = boatsAdriana.find(
            (boat) => boat.routeName === params.yachtName
          );
          if (selectedBoat) setYachtDetails(selectedBoat);
          break;

        case "phelipe-mestre":
          selectedBoat = boatsPhelipe.find(
            (boat) => boat.routeName === params.yachtName
          );
          if (selectedBoat) setYachtDetails(selectedBoat);
          break;

        default:
          selectedBoat = boatsPhelipe.find(
            (boat) => boat.routeName === params.yachtName
          );
          if (selectedBoat) setYachtDetails(selectedBoat);

          break;
      }
    } else {
      navigate("/");
    }
  }, [navigate, params.brokerName, params.yachtName]);

  useEffect(() => {
    initGA();

    const captureUTMParams = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const allParams: Record<string, string> = {};

      urlParams.forEach((value, key) => {
        allParams[key] = value;
      });

      if (Object.keys(allParams).length > 0) {
        localStorage.setItem("urlParams", JSON.stringify(allParams));

        logEvent("URL Params", "Capture", JSON.stringify(allParams));
      }
    };

    const cleanURL = () => {
      if (window.location.search) {
        const url = new URL(window.location.href);
        url.search = "";
        window.history.replaceState({}, document.title, url.toString());
      }
    };

    captureUTMParams();
    cleanURL();
    logPageView();
  }, []);

  // useEffect(() => {
  //   if (selectYacht) {
  //     setBoatSpecifications(selectYacht);
  //     const broker = brokersList.filter(
  //       (broker) => broker.name === selectYacht.broker
  //     );

  //     setBrokerInfo(broker[0]);
  //   }
  // }, [yachtName, selectYacht]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setShowPriceCard(true);
      } else {
        setShowPriceCard(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
      setWindowSizeHeight(window.innerHeight);
      console.log(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const scrollTarget = localStorage.getItem("scrollTarget");

    if (scrollTarget) {
      const checkElementAndScroll = () => {
        const element = document.querySelector(scrollTarget);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });

          localStorage.removeItem("scrollTarget");

          clearInterval(interval);
        }
      };

      const interval = setInterval(checkElementAndScroll, 100);
    }
  }, []);

  useEffect(() => {
    const listReserves = async () => {
      const response = await getReservesFromCalendar();

      const dateTimeObj = response.res.map((event: ICalendarEvent) => {
        return {
          expDateStart: formatDateTimeObj(event.start.dateTime).date,
          expHourMinutesStart: formatDateTimeObj(event.start.dateTime).time,
          expDateEnd: formatDateTimeObj(event.end.dateTime).date,
          expHourMinutesEnd: formatDateTimeObj(event.end.dateTime).time,
        } as IExpDateTime;
      });
      setExpObject(dateTimeObj);
    };

    listReserves();
  }, []);

  const sumStartTimeAndDuration = (
    startTime: string | undefined,
    duration: string | undefined
  ) => {
    if (!startTime || !duration) return;

    const [time, period] = startTime.split(" ");
    let [hour, min] = time.split(":").map(Number);

    if (period === "PM" && hour !== 12) {
      hour += 12;
    }
    if (period === "AM" && hour === 12) {
      hour = 0;
    }

    hour += Number(duration.split("hrs")[0]);

    const finalPeriod = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }

    return `${hour}:${min.toString().padStart(2, "0")} ${finalPeriod}`;
  };

  const handleImageClick = (img: { id: string; img: string }) => {
    setActiveImageId(img.id);
  };

  const formateDateSendEmail = (date: string /* YYYY/MM/DD */) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    const [year, month, day] = date.split("/");
    const monthName = months[parseInt(month, 10) - 1];
    return `${monthName} ${parseInt(day, 10)}, ${year}`;
  };

  const formBookingSubmit = async (reserve: FormYachtObj) => {
    const eventDetails: EventDetails = {
      user_name: reserve.userName as string,
      user_email: reserve.userEmail,
      start_date: reserve.dateExpBegin as string,
      end_date: reserve.dateExpEnd || reserve.dateExpBegin || "",
      number_passenger: Number(reserve.passengerNumber),
      hours: reserve.duration ?? "",
      boat: yachtDetails?.name.replace("'", "") || "",
      user_phone: reserve.userPhone as string,
      end_hour:
        sumStartTimeAndDuration(reserve.startTime, reserve?.duration) || "",
      start_hour: reserve.startTime as string,
    };

    const calendarUrl = formatGoogleCalendarUrl(eventDetails);

    const reserveData: SendEmailReserve = {
      boat: yachtDetails?.name.replace("'", "") || "",
      // bookingValue: reserve.bookingValue as string,
      // captainValue: reserve.captainValue as string,
      discount:
        !reserve.discount || reserve.discount === ""
          ? undefined
          : reserve.discount,
      // totalValue: reserve.totalValue,
      end_date: reserve.dateExpEnd
        ? formateDateSendEmail(reserve.dateExpEnd)
        : undefined,
      google_calendar_url: calendarUrl,
      hour:
        !reserve.duration || reserve.duration === ""
          ? undefined
          : reserve.duration,
      name: reserve.userName as string,
      number_passenger: Number(reserve.passengerNumber),
      start_date: formateDateSendEmail(reserve.dateExpBegin as string),
      startTime: reserve.startTime as string,
      to: reserve.userEmail,
    };

    let viaLink = localStorage.getItem("urlParams");

    const reserveDataInfo: SendEmailReserveInfo = {
      boat: yachtDetails?.name.replace("'", "") || "",
      bookingValue: reserve.bookingValue as string,
      captainValue: reserve.captainValue as string,
      discount:
        !reserve.discount || reserve.discount === ""
          ? undefined
          : reserve.discount,
      totalValue: reserve.totalValue,
      end_date: reserve.dateExpEnd
        ? formateDateSendEmail(reserve.dateExpEnd)
        : undefined,
      google_calendar_url: calendarUrl,
      hour:
        !reserve.duration || reserve.duration === ""
          ? undefined
          : reserve.duration,
      name: reserve.userName as string,
      number_passenger: Number(reserve.passengerNumber),
      start_date: formateDateSendEmail(reserve.dateExpBegin as string),
      startTime: reserve.startTime as string,
      to: reserve.userEmail,
      clientEmail: reserve.userEmail,
      clientPhone: reserve.userPhone as string,
      linkParameter: viaLink
        ? Object.values(JSON.parse(viaLink)).map((value) => value + ", ")
        : undefined,
      specialCode: reserve.specialCode ? reserve.specialCode : undefined,
      emailBroker: brokerInfo?.email || undefined,

      brokerName: brokerInfo?.name || undefined,
    };

    const sendEmailRequest = await sendEmailReserve(reserveData);
    const sendEmailRequestInfo = await sendEmailReserveInfo(reserveDataInfo);

    if (
      sendEmailRequest.status === 200 &&
      sendEmailRequestInfo.status === 200
    ) {
      localStorage.clear();
    }
  };

  const bookYachtHandler = () => {
    setOpenForm((prev) => !prev);
  };

  const avatarClickHandler = () => {
    navigate(`/broker/${params.brokerName}`);
  };

  return (
    <>
      <div id="detail-page">
        <LayoutDefault>
          {yachtDetails?.images ? (
            <>
              <CarouselDefault
                imagesCarousel={yachtDetails?.images?.map((img) => {
                  return {
                    id: img.id,
                    img: img.img,
                    type: img.type as "photo" | "video",
                  };
                })}
                activeImageId={activeImageId}
                infoText={{
                  title: yachtDetails?.name || "",
                  subTitle:
                    windowSize >= 1160
                      ? (yachtDetails?.texts?.subTitle as string)
                      : "",
                  buttonText: "Instant Book",
                  buttonClick: () => {
                    const element = document.querySelector(
                      "#yacht-booking-form"
                    );
                    if (element && windowSize >= 768) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                    if (windowSize < 768) {
                      bookYachtHandler();
                    }
                  },
                }}
              />
              <SlideCarousel
                imagesCarousel={yachtDetails?.images?.map((img) => {
                  return {
                    id: img.id,
                    img: img.img,
                    type: img.type as "photo" | "video",
                  };
                })}
                onImageClick={handleImageClick}
              />
            </>
          ) : (
            false
          )}

          <div className="boat-info-wrapper">
            {params.yachtName === "2022-nx-horizon-40" ? (
              <img loading="eager" src={NXHorizon} alt="Boat NX-horizon-40" />
            ) : (
              <img loading="eager" src={logo} alt="yacht charter logo" />
            )}
            <div className="title-paragraph-wrapper">
              <TitleParagraph
                title={yachtDetails?.name || ""}
                paragraph={yachtDetails?.texts?.paragraph}
                textAlign="center"
                paragraphColor="gray"
                isBannerTitle
              />
            </div>
          </div>
          <hr />
          <div className="detail-wrapper">
            {windowSize < 768 && params.yachtName === "2022-nx-horizon-40" ? (
              <div className="video-wrapper">
                <iframe
                  className="video-player"
                  src="https://www.youtube.com/embed/cVBMwjWEg_4?si=smC2FjuUaQZSUPdi"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
            <div className="detail-inner-wrapper">
              <div className="detail-left">
                {windowSize >= 768 &&
                params.yachtName === "2022-nx-horizon-40" ? (
                  <div className="video-wrapper">
                    <iframe
                      className="video-player"
                      src="https://www.youtube.com/embed/cVBMwjWEg_4?si=smC2FjuUaQZSUPdi"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : null}

                <Details boat={selectedBoatSpecifications?.name || ""} />

                {windowSize >= 768 ? (
                  <DetailsCaption
                    boat={selectedBoatSpecifications?.name || ""}
                  />
                ) : null}
              </div>
              <div className="detail-right">
                {/* {boatSpecifications.routeName === "2022-nx-horizon-40" ? ( */}
                <>
                  {windowSize >= 768 ? (
                    <YachtBookingForm
                      avatarClick={avatarClickHandler}
                      broker={{
                        avatar: brokerInfo?.avatar || "",
                        name: brokerInfo?.name || "",
                        subText: brokerInfo?.subText || "",
                      }}
                      isVisible
                      setVisible={setOpenForm}
                      headerFormTitle="Book this yacht"
                      headerFormSubTitle="Get ready to enjoy your day on the water"
                      priceReserveFor3hr={
                        Number(
                          selectedBoatSpecifications?.base_price?.replace(
                            "$",
                            ""
                          )
                        ) || 0
                      }
                      // captainPriceFor3hrs={
                      //   yachtDetails.texts.subTitle
                      // }
                      formInformationCallback={formBookingSubmit}
                      expDateTime={expObject}
                    />
                  ) : null}
                  <SpecificationsCard boat={selectedBoatSpecifications} />
                </>
                {/* ) : (
                  <SpecificationsCard01 />
                )} */}
              </div>
            </div>

            {windowSize < 768 ? (
              <>
                <hr />{" "}
                <DetailsCaption boat={selectedBoatSpecifications?.name || ""} />
              </>
            ) : null}
          </div>
        </LayoutDefault>

        <YachtBookingForm
          avatarClick={avatarClickHandler}
          isVisible={openForm}
          setVisible={setOpenForm}
          headerFormTitle="Book this yacht"
          headerFormSubTitle="Get ready to enjoy your day on the water"
          priceReserveFor3hr={Number(
            selectedBoatSpecifications?.base_price?.replace("$", "")
          )}
          // captainPriceFor3hrs={boats[0].captainPrices.captainPricePerHour}
          formInformationCallback={formBookingSubmit}
          expDateTime={expObject}
        />
      </div>
      {windowSize < 768 && !openForm && showPriceCard && (
        <PriceCard
          price={selectedBoatSpecifications?.base_price?.replace("$", "") || "	"}
          onClick={bookYachtHandler}
        />
      )}
    </>
  );
};

export default DetailPage;

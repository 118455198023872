import React from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import SubText from "../../components/Texts/SubText";
import boat from "../../assets/images/nx400/yacht-charter-nx-40.png";
import Button from "../../components/Buttons/Button";
import { useNavigate } from "react-router-dom";

const PageNotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const contactUsHandler = () => {};

  return (
    <LayoutDefault className="page-not-found">
      <div className="page-not-found__wrapper">
        <main className="wrapper__card-not-found">
          <img src={boat} alt="boat" className="card-not-found__boat" />
          <h1 className="card-not-found__404-text">
            <b>404</b> - Lost at Sea
          </h1>
          <SubText textAlign="center">
            Oops! It looks like you’ve drifted off course. The page you’re
            searching for might have sailed away or dropped anchor somewhere
            else.
          </SubText>
          <div className="card-not-found__buttons-container">
            <Button onClick={() => navigate("/")} buttonStyle="secondary">
              Return Home
            </Button>
            <Button onClick={() => navigate("/")} buttonStyle="primary">
              Explore Our Fleet
            </Button>
            <Button onClick={contactUsHandler} buttonStyle="secondary">
              Contact Us
            </Button>
          </div>
          <SubText textAlign="center">
            Don’t worry—we’ll help you navigate back to smooth sailing! ⚓✨
          </SubText>
        </main>
      </div>
    </LayoutDefault>
  );
};

export default PageNotFoundPage;

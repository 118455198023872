import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import BrokerCard from "../../components/Cards/BrokerCard";
import { brokersList } from "../../mocks/brokersDetails";
import { yachtsBrokers } from "../../mocks/yachtsBrokers";
import YachtCard from "../../components/Cards/YachtCard";
import { YachtBroker } from "../../@types/yachtBrokers";
import { SelectYachtContext } from "../../contexts/selectYachtContext";
import { useNavigate, useParams } from "react-router-dom";
import { textCapitalizeArray } from "../../utils/textCapitalize";
import ContactBrokerModal from "../../components/Modals/ContactBrokerModal";
import { Helmet } from "react-helmet-async";
import metaImage from "../../assets/images/yachtcharater-meta.png";

const BrokerYachtListPage: React.FC = () => {
  const { setSelectYacht } = useContext(SelectYachtContext);
  const navigate = useNavigate();
  const params = useParams();
  const [brokerName, setBrokerName] = useState("");
  const [brokerEmail, setBrokerEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [boatsNamesList, setBoatsNamesList] = useState<string[] | []>([]);
  const [brokerAvatar, setBrokerAvatar] = useState("");

  useEffect(() => {
    if (params.brokerName) {
      const brokerNameChange = textCapitalizeArray(
        params?.brokerName.split("-")
      )?.join(" ");

      const listNamesBrokers = brokersList.find(
        (broker) => broker.name === brokerNameChange
      );

      if (!listNamesBrokers?.name) {
        return navigate("/");
      }

      setBrokerAvatar(
        brokersList.find((broker) => broker.name === brokerName)?.avatar || ""
      );

      setBrokerName(brokerNameChange);
      setBrokerEmail(listNamesBrokers?.email || "");

      let listBoats = yachtsBrokers
        .filter((b_filter) => b_filter.broker === brokerName)
        .map((boat) => boat.name);

      listBoats.unshift("NX Horizon 40'");

      setBoatsNamesList(listBoats);
    } else {
      navigate("/");
    }
  }, [brokerName, navigate, params.brokerName]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isModalOpen]);

  const yachtDetailsHandler = (yacht: YachtBroker) => {
    const broker = yacht.broker
      .toLowerCase()
      .replaceAll(" ", "-")
      .replaceAll("’", " ")
      .trim();

    setSelectYacht(yacht);

    navigate(`/yacht/${broker}/${yacht.routeName}`);
  };

  const clickBrokerAvatar = () => {
    navigate(`/broker/${params.brokerName}`);
  };

  return (
    <>
      {/* <Helmet>
        <title>Yacht Charter Miami | {brokerName}</title>
        <meta
          name="description"
          content="The Experience of a Life Time in Miami"
        />
        <meta
          property="og:title"
          content={`Yacht Charter Miami | ${brokerName}`}
        />
        <meta
          property="og:description"
          content="The Experience of a Life Time in Miami"
        />
        <meta property="og:image" content={brokerAvatar || metaImage} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <meta
          property="og:url"
          content={`%PUBLIC_URL%/broker/${params.brokerName}`}
        />
      </Helmet> */}
      <LayoutDefault className="broker-yacht-list" solidNavbar>
        <ContactBrokerModal
          brokerName={brokerName}
          brokerEmail={brokerEmail}
          closeModal={() => setIsModalOpen(false)}
          boatsOptions={boatsNamesList.map((boat) => {
            return { label: boat, value: boat };
          })}
          isOpenModal={isModalOpen}
        />
        <BrokerCard
          clickAvatar={clickBrokerAvatar}
          brokerAvatar={
            brokersList.find((broker) => broker.name === brokerName)?.avatar ||
            ""
          }
          brokerName={brokerName}
          clickContact={() => setIsModalOpen(true)}
          className="broker-yacht-list__broker-card"
        />
        <div className="broker-yacht-list__cards-wrapper">
          {yachtsBrokers
            .sort((a, b) => {
              if (a.broker === "Phelipe Mestre") return -1;
              if (b.broker === "Phelipe Mestre") return 1;
              return 0;
            })
            .filter((b_filter) => {
              return (
                Object.values(b_filter).includes("Phelipe Mestre") ||
                b_filter.broker === brokerName
              );
            })

            .map((yacht, index) => {
              return (
                <YachtCard
                  specialOffer={yacht.broker === "Phelipe Mestre"}
                  key={index}
                  yachtName={yacht.name}
                  startPrice={yacht.base_price}
                  // yachtSpecification={yacht.model}
                  yachtImage={yacht.image}
                  buttonClick={() => yachtDetailsHandler(yacht as YachtBroker)}
                />
              );
            })}
        </div>
      </LayoutDefault>
    </>
  );
};

export default BrokerYachtListPage;

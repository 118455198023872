import { ReactNode, ReactElement } from "react";
import { SelectYachtProvider } from "./contexts/selectYachtContext";

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps): ReactElement => {
  return <SelectYachtProvider>{children}</SelectYachtProvider>;
};

export default AppProvider;

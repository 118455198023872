import { Outlet } from "react-router-dom";
import MetaTags from "../MetaTags";

const MainLayout = () => {
  return (
    <>
      <MetaTags />
      <Outlet />
    </>
  );
};

export default MainLayout;

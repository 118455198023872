import React from "react";
import "./styles.scss";
import Button from "../../Buttons/Button";

interface YachtCardProps {
  className?: string;
  yachtName: string;
  yachtSpecification?: string;
  yachtImage: string;
  buttonClick: () => void;
  startPrice?: string;
  specialOffer: boolean;
}

const YachtCard: React.FC<YachtCardProps> = (props) => {
  const {
    yachtName,
    yachtSpecification,
    yachtImage,
    className,
    buttonClick,
    startPrice,
    specialOffer,
  } = props;

  return (
    <div
      id="yacht-card"
      className={`${className ?? ""} ${
        specialOffer ? "border-highlighted" : ""
      }`}
    >
      <figure className="yacht-card__yacht-image-wrapper">
        <img
          src={yachtImage}
          onClick={buttonClick}
          alt={`${yachtName}, ${yachtSpecification}`}
          className="yacht-image-wrapper__content"
        />
      </figure>
      <h4 className="no-margin">{yachtName}</h4>
      <hr />
      {startPrice ? (
        <p className="start-price">
          Starting at <span> {startPrice} </span> /4hrs
        </p>
      ) : (
        <p className="start-price">Call for Pricing</p>
      )}
      <div className="yacht-card__button-container">
        <Button
          buttonStyle={specialOffer ? "primary" : "secondary"}
          onClick={buttonClick}
        >
          {specialOffer ? "Special Offer" : "View Details"}
        </Button>
      </div>
    </div>
  );
};

export default YachtCard;

// Sunseeker 75
import sunsekekerImage1 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_1.jpg";
import sunsekekerImage2 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_2.jpg";
import sunsekekerImage3 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_3.jpg";
import sunsekekerImage4 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_4.jpg";
import sunsekekerImage5 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_5.jpg";
import sunsekekerImage6 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_6.jpg";
import sunsekekerImage7 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_7.jpg";
import sunsekekerImage8 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_8.jpg";
import sunsekekerImage9 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_9.jpg";
import sunsekekerImage10 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_10.jpg";
import sunsekekerImage11 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_11.jpg";
import sunsekekerImage12 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_12.jpg";
import sunsekekerImage13 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_13.jpg";
import sunsekekerImage14 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_14.jpg";
import sunsekekerImage15 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_15.jpg";
import sunsekekerImage16 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_16.jpg";
import sunsekekerImage17 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_17.jpg";
import sunsekekerImage18 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_18.jpg";
import sunsekekerImage19 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_19.jpg";
import sunsekekerImage20 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_20.jpg";
import sunsekekerImage21 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_21.jpg";
import sunsekekerImage22 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_22.jpg";
import sunsekekerImage23 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_23.jpg";
import sunsekekerImage24 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_24.jpg";
import sunsekekerImage25 from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_25.jpg";

//fairline-flybridge-55

import fairlineFlybridgeImage1 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_1.jpg";
import fairlineFlybridgeImage2 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_2.jpg";
import fairlineFlybridgeImage3 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_3.jpg";
import fairlineFlybridgeImage4 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_4.jpg";
import fairlineFlybridgeImage5 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_5.jpg";
import fairlineFlybridgeImage6 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_6.jpg";
import fairlineFlybridgeImage7 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_7.jpg";
import fairlineFlybridgeImage8 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_8.jpg";
import fairlineFlybridgeImage9 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_9.jpg";
import fairlineFlybridgeImage10 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_10.jpg";
import fairlineFlybridgeImage11 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_11.jpg";
import fairlineFlybridgeImage12 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_12.jpg";
import fairlineFlybridgeImage13 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_13.jpg";
import fairlineFlybridgeImage14 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_14.jpg";
import fairlineFlybridgeImage15 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_15.jpg";
import fairlineFlybridgeImage16 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_16.jpg";
import fairlineFlybridgeImage17 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_17.jpg";
import fairlineFlybridgeImage18 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_18.jpg";
import fairlineFlybridgeImage19 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_19.jpg";
import fairlineFlybridgeImage20 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_20.jpg";
import fairlineFlybridgeImage21 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_21.jpg";
import fairlineFlybridgeImage22 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_22.jpg";
import fairlineFlybridgeImage23 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_23.jpg";
import fairlineFlybridgeImage24 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_24.jpg";
import fairlineFlybridgeImage25 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_25.jpg";
import fairlineFlybridgeImage26 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_26.jpg";
import fairlineFlybridgeImage27 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_27.jpg";
import fairlineFlybridgeImage28 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_28.jpg";
import fairlineFlybridgeImage29 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_29.jpg";
import fairlineFlybridgeImage30 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_30.jpg";
import fairlineFlybridgeImage31 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_31.jpg";
import fairlineFlybridgeImage32 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_32.jpg";
import fairlineFlybridgeImage33 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_33.jpg";
import fairlineFlybridgeImage34 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_34.jpg";
import fairlineFlybridgeImage35 from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_35.jpg";

//Prestige 60
import prestigeImage1 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_1.jpg";
import prestigeImage2 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_2.jpg";
import prestigeImage3 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_3.jpg";
import prestigeImage4 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_4.jpg";
import prestigeImage5 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_5.jpg";
import prestigeImage6 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_6.jpg";
import prestigeImage7 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_7.jpg";
import prestigeImage8 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_8.jpg";
import prestigeImage9 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_9.jpg";
import prestigeImage10 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_10.jpg";
import prestigeImage11 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_11.jpg";
import prestigeImage12 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_12.jpg";
import prestigeImage13 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_13.jpg";
import prestigeImage14 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_14.jpg";
import prestigeImage15 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_15.jpg";
import prestigeImage16 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_16.jpg";
import prestigeImage17 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_17.jpg";
import prestigeImage18 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_18.jpg";
import prestigeImage19 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_19.jpg";
import prestigeImage20 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_20.jpg";
import prestigeImage21 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_21.jpg";
import prestigeImage22 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_22.jpg";
import prestigeImage23 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_23.jpg";
import prestigeImage24 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_24.jpg";
import prestigeImage25 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_25.jpg";
import prestigeImage26 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_26.jpg";
import prestigeImage27 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_27.jpg";
import prestigeImage28 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_28.jpg";
import prestigeImage29 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_29.jpg";
import prestigeImage30 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_30.jpg";
import prestigeImage31 from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_31.jpg";

//Pershing Loki 65
import pershingLokiImage1 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_1.jpg";
import pershingLokiImage2 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_2.jpg";
import pershingLokiImage3 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_3.jpg";
import pershingLokiImage4 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_4.jpg";
import pershingLokiImage5 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_5.jpg";
import pershingLokiImage6 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_6.jpg";
import pershingLokiImage7 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_7.jpg";
import pershingLokiImage8 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_8.jpg";
import pershingLokiImage9 from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_9.jpg";

const boats = [
  {
    name: "Sunseeker 75'",
    specification: "",
    texts: {
      title: "Sunseeker 75’",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family
			 outing, or celebrating a special occasion, we’ve got you covered with
			 an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
			 stunning Sunseeker 75' Yacht, exclusively offered by Yacht
			 Charter Miami. Our bespoke yacht charters promise an unparalleled
			 luxury experience.`,
    },
    routeName: "75-sunseeker",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: sunsekekerImage1, type: "photo" },
      { id: "1", img: sunsekekerImage2, type: "photo" },
      { id: "2", img: sunsekekerImage3, type: "photo" },
      { id: "3", img: sunsekekerImage4, type: "photo" },
      { id: "4", img: sunsekekerImage5, type: "photo" },
      { id: "5", img: sunsekekerImage6, type: "photo" },
      { id: "6", img: sunsekekerImage7, type: "photo" },
      { id: "7", img: sunsekekerImage8, type: "photo" },
      { id: "8", img: sunsekekerImage9, type: "photo" },
      { id: "9", img: sunsekekerImage10, type: "photo" },
      { id: "10", img: sunsekekerImage11, type: "photo" },
      { id: "11", img: sunsekekerImage12, type: "photo" },
      { id: "12", img: sunsekekerImage13, type: "photo" },
      { id: "13", img: sunsekekerImage14, type: "photo" },
      { id: "14", img: sunsekekerImage15, type: "photo" },
      { id: "15", img: sunsekekerImage16, type: "photo" },
      { id: "16", img: sunsekekerImage17, type: "photo" },
      { id: "17", img: sunsekekerImage18, type: "photo" },
      { id: "18", img: sunsekekerImage19, type: "photo" },
      { id: "19", img: sunsekekerImage20, type: "photo" },
      { id: "20", img: sunsekekerImage21, type: "photo" },
      { id: "21", img: sunsekekerImage22, type: "photo" },
      { id: "22", img: sunsekekerImage23, type: "photo" },
      { id: "23", img: sunsekekerImage24, type: "photo" },
      { id: "24", img: sunsekekerImage25, type: "photo" },
    ],
  },
  {
    name: "Fairline Flybridge 55'",
    specification: "",
    texts: {
      title: "Fairline Flybridge 55’",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family
		 outing, or celebrating a special occasion, we’ve got you covered with
		 an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
		 stunning Fairline Flybridge 55' Yacht, exclusively offered by Yacht
		 Charter Miami. Our bespoke yacht charters promise an unparalleled
		 luxury experience.`,
    },
    routeName: "55-fairline-flybridge",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: fairlineFlybridgeImage1, type: "photo" },
      { id: "1", img: fairlineFlybridgeImage2, type: "photo" },
      { id: "2", img: fairlineFlybridgeImage3, type: "photo" },
      { id: "3", img: fairlineFlybridgeImage4, type: "photo" },
      { id: "4", img: fairlineFlybridgeImage5, type: "photo" },
      { id: "5", img: fairlineFlybridgeImage6, type: "photo" },
      { id: "6", img: fairlineFlybridgeImage7, type: "photo" },
      { id: "7", img: fairlineFlybridgeImage8, type: "photo" },
      { id: "8", img: fairlineFlybridgeImage9, type: "photo" },
      { id: "9", img: fairlineFlybridgeImage10, type: "photo" },
      { id: "10", img: fairlineFlybridgeImage11, type: "photo" },
      { id: "11", img: fairlineFlybridgeImage12, type: "photo" },
      { id: "12", img: fairlineFlybridgeImage13, type: "photo" },
      { id: "13", img: fairlineFlybridgeImage14, type: "photo" },
      { id: "14", img: fairlineFlybridgeImage15, type: "photo" },
      { id: "15", img: fairlineFlybridgeImage16, type: "photo" },
      { id: "16", img: fairlineFlybridgeImage17, type: "photo" },
      { id: "17", img: fairlineFlybridgeImage18, type: "photo" },
      { id: "18", img: fairlineFlybridgeImage19, type: "photo" },
      { id: "19", img: fairlineFlybridgeImage20, type: "photo" },
      { id: "20", img: fairlineFlybridgeImage21, type: "photo" },
      { id: "21", img: fairlineFlybridgeImage22, type: "photo" },
      { id: "22", img: fairlineFlybridgeImage23, type: "photo" },
      { id: "23", img: fairlineFlybridgeImage24, type: "photo" },
      { id: "24", img: fairlineFlybridgeImage25, type: "photo" },
      { id: "25", img: fairlineFlybridgeImage26, type: "photo" },
      { id: "26", img: fairlineFlybridgeImage27, type: "photo" },
      { id: "27", img: fairlineFlybridgeImage28, type: "photo" },
      { id: "28", img: fairlineFlybridgeImage29, type: "photo" },
      { id: "29", img: fairlineFlybridgeImage30, type: "photo" },
      { id: "30", img: fairlineFlybridgeImage31, type: "photo" },
      { id: "31", img: fairlineFlybridgeImage32, type: "photo" },
      { id: "32", img: fairlineFlybridgeImage33, type: "photo" },
      { id: "33", img: fairlineFlybridgeImage34, type: "photo" },
      { id: "34", img: fairlineFlybridgeImage35, type: "photo" },
    ],
  },
  {
    name: "Prestige 60'",
    specification: "",
    texts: {
      title: "Prestige 60’",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family
		 outing, or celebrating a special occasion, we’ve got you covered with
		 an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
		 stunning Prestige 60' Yacht, exclusively offered by Yacht
		 Charter Miami. Our bespoke yacht charters promise an unparalleled
		 luxury experience.`,
    },
    routeName: "60-prestige",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: prestigeImage1, type: "photo" },
      { id: "1", img: prestigeImage2, type: "photo" },
      { id: "2", img: prestigeImage3, type: "photo" },
      { id: "3", img: prestigeImage4, type: "photo" },
      { id: "4", img: prestigeImage5, type: "photo" },
      { id: "5", img: prestigeImage6, type: "photo" },
      { id: "6", img: prestigeImage7, type: "photo" },
      { id: "7", img: prestigeImage8, type: "photo" },
      { id: "8", img: prestigeImage9, type: "photo" },
      { id: "9", img: prestigeImage10, type: "photo" },
      { id: "10", img: prestigeImage11, type: "photo" },
      { id: "11", img: prestigeImage12, type: "photo" },
      { id: "12", img: prestigeImage13, type: "photo" },
      { id: "13", img: prestigeImage14, type: "photo" },
      { id: "14", img: prestigeImage15, type: "photo" },
      { id: "15", img: prestigeImage16, type: "photo" },
      { id: "16", img: prestigeImage17, type: "photo" },
      { id: "17", img: prestigeImage18, type: "photo" },
      { id: "18", img: prestigeImage19, type: "photo" },
      { id: "19", img: prestigeImage20, type: "photo" },
      { id: "20", img: prestigeImage21, type: "photo" },
      { id: "21", img: prestigeImage22, type: "photo" },
      { id: "22", img: prestigeImage23, type: "photo" },
      { id: "23", img: prestigeImage24, type: "photo" },
      { id: "24", img: prestigeImage25, type: "photo" },
      { id: "25", img: prestigeImage26, type: "photo" },
      { id: "26", img: prestigeImage27, type: "photo" },
      { id: "27", img: prestigeImage28, type: "photo" },
      { id: "28", img: prestigeImage29, type: "photo" },
      { id: "29", img: prestigeImage30, type: "photo" },
      { id: "30", img: prestigeImage31, type: "photo" },
    ],
  },
  {
    name: "Pershing Loki 65'",
    specification: "",
    texts: {
      title: "Pershing Loki 65’",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family
		 outing, or celebrating a special occasion, we’ve got you covered with
		 an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
		 stunning Pershing Loki 65' Yacht, exclusively offered by Yacht
		 Charter Miami. Our bespoke yacht charters promise an unparalleled
		 luxury experience.`,
    },
    routeName: "65-pershing-loki",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: pershingLokiImage1, type: "photo" },
      { id: "1", img: pershingLokiImage2, type: "photo" },
      { id: "2", img: pershingLokiImage3, type: "photo" },
      { id: "3", img: pershingLokiImage4, type: "photo" },
      { id: "4", img: pershingLokiImage5, type: "photo" },
      { id: "5", img: pershingLokiImage6, type: "photo" },
      { id: "6", img: pershingLokiImage7, type: "photo" },
      { id: "7", img: pershingLokiImage8, type: "photo" },
      { id: "8", img: pershingLokiImage9, type: "photo" },
    ],
  },
];

export default boats;

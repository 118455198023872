import React, { ReactNode } from "react";
import "./styles.scss";

interface SubTextProps {
  icon?: string;
  altIcon?: string;
  children: ReactNode;
  checkAnswer?: boolean;
  checkValueCallback?: (a: boolean) => void;
  isChecked?: boolean;
  fontFamily?: "futura" | "roboto" | "spectral" | "montserrat" | "SF";
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
}

const SubText: React.FC<SubTextProps> = (props) => {
  const {
    icon,
    altIcon,
    children,
    checkAnswer,
    checkValueCallback,
    isChecked,
    fontFamily = "roboto",
    textAlign,
  } = props;

  return (
    <div id="icon-and-subtitle">
      {icon && <img src={icon} alt={altIcon} />}
      {checkAnswer && checkValueCallback && (
        <label className="container">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => checkValueCallback(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>
      )}
      <div style={{ textAlign }} className={`subtitle-text ${fontFamily}`}>
        {children}
      </div>
    </div>
  );
};

export default SubText;

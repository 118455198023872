import React from "react";
import "./styles.scss";
import Hr from "../Hr";
import EmailIcon from "../../assets/icons/social-medias/email-icon.svg";
import TwitterLogo from "../../assets/icons/social-medias/twitter-logo.svg";
import InstagramLogo from "../../assets/icons/social-medias/instagram-logo.svg";
import yachtCharterFooterLogo from "../../assets/logos/yacht_charter_footer_logo.svg";

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
  const {} = props;

  return (
    <>
      <footer id="footer-default">
        <div className="copy-right">
          <img
            className="footer-logo"
            src={yachtCharterFooterLogo}
            alt="Yacht Charter"
          />
          <p>Yacht Charter • Miami | Copyright © | All Rights 2025</p>
          <div className="social-medias">
            <a href="mailto:yachtcharterfl@gmail.com">
              <img src={EmailIcon} alt="email icon" />
            </a>
            <a
              href="https://www.instagram.com/yachtcharter.miami_/"
              target="blank_"
            >
              <img src={InstagramLogo} alt="instagram icon" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

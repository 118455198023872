import azimutImage1 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_1.jpg";
import azimutImage2 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_2.jpg";
import azimutImage3 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_3.jpg";
import azimutImage4 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_4.jpg";
import azimutImage5 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_5.jpg";
import azimutImage6 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_6.jpg";
import azimutImage7 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_7.jpg";
import azimutImage8 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_8.jpg";
import azimutImage9 from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_9.jpg";

const boats = [
  {
    name: "Sunseeker 75'",
    specification: "",
    texts: {
      title: "Sunseeker 75’",
      subTitle: `Whether you’re hosting a corporate event, enjoying a family
			 outing, or celebrating a special occasion, we’ve got you covered with
			 an unforgettable yacht experience in Miami.`,
      paragraph: `Set sail on an unforgettable journey aboard the 
			 stunning Sunseeker 75' Yacht, exclusively offered by Yacht
			 Charter Miami. Our bespoke yacht charters promise an unparalleled
			 luxury experience.`,
    },
    routeName: "75-sunseeker",
    vehicleImg: "",
    showComingSoon: false,
    images: [
      { id: "0", img: azimutImage1, type: "photo" },
      { id: "1", img: azimutImage2, type: "photo" },
      { id: "2", img: azimutImage3, type: "photo" },
      { id: "3", img: azimutImage4, type: "photo" },
      { id: "4", img: azimutImage5, type: "photo" },
      { id: "5", img: azimutImage6, type: "photo" },
      { id: "6", img: azimutImage7, type: "photo" },
      { id: "7", img: azimutImage8, type: "photo" },
      { id: "8", img: azimutImage9, type: "photo" },
    ],
  },
];

export default boats;

import React from "react";
import "./styles.scss";
import YachtCharterLogo from "../../../assets/logos/yacht-charter-logo.png";
import Hr from "../../Hr";
import Button from "../../Buttons/Button";
import SubText from "../../Texts/SubText";

interface BrokerCardProps {
  className?: string;
  brokerAvatar: string;
  brokerName: string;
  clickContact: () => void;
  clickAvatar: () => void;
}

const BrokerCard: React.FC<BrokerCardProps> = (props) => {
  const { className, brokerAvatar, brokerName, clickContact, clickAvatar } =
    props;

  return (
    <div className={className ?? ""} id="broker-card">
      <div className="broker-card__wrapper">
        <figure className="wrapper__yacht-charter-logo-wrapper">
          <img src={YachtCharterLogo} alt="yacht charter logo" />
        </figure>
        <hr className="spacer top" />
        <figure className="wrapper__broker-avatar-wrapper">
          <img
            src={brokerAvatar}
            alt={`${brokerName}`}
            className="broker-avatar-wrapper__avatar"
            onClick={clickAvatar}
          />
          <figcaption className="broker-avatar-wrapper__name">
            {brokerName}
          </figcaption>
          <span className="broker-avatar-wrapper__detail-text">
            Yacht Broker
          </span>
        </figure>
        <hr className="spacer middle" />
        <SubText textAlign="center">
          Whether you're commuting, exploring, or just seeking a relaxing stay
          in Miami, we got you covered.
        </SubText>

        <div className="wrapper__button-wrapper">
          <Button buttonStyle="primary" onClick={clickContact}>
            Contact me
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BrokerCard;

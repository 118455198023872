import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import CloseIcon from "../../../assets/icons/bx-x.svg";
import Select from "../../Selects/Select";
import { startTimeObject } from "../../../mocks/startTimeObject";
import { passengersObject } from "../../../mocks/passengersObject";
import { durationObject } from "../../../mocks/durationObject";
import ClockIcon from "../../../assets/icons/bx-time-five.svg";
import PersonIcon from "../../../assets/icons/person-icon-gray.svg";
import InputText from "../../Inputs/InputText";
import Hr from "../../Hr";
import ListSelect, { IOptionListSelectProps } from "../../Selects/ListSelect";
import Button from "../../Buttons/Button";
import CircleCheckGreen from "../../../assets/icons/bxs-check-circle.svg";
import CircleCrossRed from "../../../assets/icons/bxs-x-circle.svg";
import {
  sendEmailReserveInfo,
  SendEmailReserveInfo,
} from "../../../api/reserves/sendEmailReserveInfo";
import {
  sendEmailReserve,
  SendEmailReserve,
} from "../../../api/reserves/sendEmailReserve";
import {
  EventDetails,
  formatGoogleCalendarUrl,
} from "../../../utils/googleCalendarUrlFormatter";
import { FormYachtObj } from "../../../@types/formYachtObj";
import DatePicker from "react-datepicker";
import codesMock from "../../../mocks/countryCodes.json";
import SimpleSelect from "../../Selects/SimpleSelect";
import { CountryCode } from "libphonenumber-js";
import { setupPhoneNumberInput } from "../../../utils/masks";

interface ContactBrokerModalProps {
  className?: string;
  boatsOptions: IOptionListSelectProps[];
  isOpenModal: boolean;
  closeModal: () => void;
  brokerName: string;
  brokerEmail: string;
}

const ContactBrokerModal: React.FC<ContactBrokerModalProps> = (props) => {
  const {
    className,
    boatsOptions,
    isOpenModal,
    closeModal,
    brokerName,
    brokerEmail,
  } = props;
  const [valueInputDate, setValueInputDate] = useState("");
  const [passengerNumber, setPassengerNumber] = useState("");
  const [passengerNumberWarning, setPassengerNumberWarning] = useState(false);
  const [duration, setDuration] = useState("");
  const [durationWarning, setDurationWarning] = useState(false);
  const [isStartTimeWarning, setIsStartTimeWarning] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameWarning, setUserNameWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [selectedBoat, setSelectedBoat] = useState("");
  const [selectedBoatWarning, setSelectedBoatWarning] = useState(false);
  const [dateSelected, setDateSelected] = useState("");
  const [dateWarning, setDateWarning] = useState();
  const contactBrokerModalRef = useRef<HTMLDialogElement | null>(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [widescreen, setWidescreen] = useState(window.innerWidth);
  const [userPhone, setUserPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [phoneCodeWarning, setPhoneCodeWarning] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState("" as string);
  const [userPhoneWarning, setUserPhoneWarning] = useState(false);
  const [dateExpBeginWarning, setDateExpBeginWarning] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);

  useEffect(() => {
    if (isOpenModal) {
      contactBrokerModalRef.current?.showModal();
    } else {
      contactBrokerModalRef.current?.close();
    }
  }, [isOpenModal]);

  useEffect(() => {
    const handleResize = () => {
      setWidescreen(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date
      .toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })
      .replace(/^(\w+)/, (m) => m.toLowerCase());
  };

  const sumStartTimeAndDuration = (
    startTime: string | undefined,
    duration: string | undefined
  ) => {
    if (!startTime || !duration) return;

    const [time, period] = startTime.split(" ");
    let [hour, min] = time.split(":").map(Number);

    if (period === "PM" && hour !== 12) {
      hour += 12;
    }
    if (period === "AM" && hour === 12) {
      hour = 0;
    }

    hour += Number(duration.split("hrs")[0]);

    const finalPeriod = hour >= 12 ? "PM" : "AM";
    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }

    return `${hour}:${min.toString().padStart(2, "0")} ${finalPeriod}`;
  };

  const inputsVerification = () => {
    if (!selectedBoat) {
      setSelectedBoatWarning(true);
      setTimeout(() => setSelectedBoatWarning(false), 3000);
      return;
    }

    if (!userName) {
      setUserNameWarning(true);
      setTimeout(() => setUserNameWarning(false), 3000);
      return;
    }

    if (!userEmail) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    if (!phoneCode) {
      setPhoneCodeWarning(true);
      setTimeout(() => setPhoneCodeWarning(false), 3000);
      return;
    }

    if (!userPhone) {
      setUserPhoneWarning(true);
      setTimeout(() => setUserPhoneWarning(false), 3000);
      return;
    }

    if (!duration && !endDate) {
      setDurationWarning(true);
      setTimeout(() => setDurationWarning(false), 3000);
      return;
    }

    if (!passengerNumber) {
      setPassengerNumberWarning(true);
      setTimeout(() => setPassengerNumberWarning(false), 3000);
      return;
    }

    if (!startTime) {
      setIsStartTimeWarning(true);
      setTimeout(() => setIsStartTimeWarning(false), 3000);
      return;
    }

    formBookingSubmit();
  };

  const formBookingSubmit = async () => {
    const eventDetails: EventDetails = {
      user_name: userName,
      user_email: userEmail,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate) || formatDate(startDate) || "",
      number_passenger: Number(passengerNumber),
      hours: duration ?? "",
      boat: selectedBoat.replace("'", "") || "",
      user_phone: userPhone as string,
      end_hour: sumStartTimeAndDuration(startTime, duration) || "",
      start_hour: startTime,
    };

    const calendarUrl = formatGoogleCalendarUrl(eventDetails);

    const reserveData: SendEmailReserve = {
      boat: selectedBoat.replace("'", "") || "",
      // bookingValue: "",
      // captainValue: "",
      discount: undefined,
      // totalValue: "",
      end_date: endDate ? formatDate(endDate) : undefined,
      google_calendar_url: calendarUrl,
      hour: duration,
      name: userName,
      number_passenger: Number(passengerNumber),
      start_date: formatDate(startDate),
      startTime: startTime,
      to: userEmail,
    };

    let viaLink = localStorage.getItem("urlParams");

    const reserveDataInfo: SendEmailReserveInfo = {
      boat: selectedBoat.replace("'", "") || "",
      bookingValue: "",
      captainValue: "",
      discount: undefined,
      totalValue: "",
      end_date: endDate ? formatDate(endDate) : undefined,
      google_calendar_url: calendarUrl,
      hour: duration,
      name: userName,
      number_passenger: Number(passengerNumber),
      start_date: formatDate(startDate),
      startTime: startTime,
      to: userEmail,
      clientEmail: userEmail,
      clientPhone: userPhone,
      linkParameter: undefined,
      specialCode: undefined,
      emailBroker: brokerEmail,
      brokerName: brokerName,
    };

    const sendEmailRequest = await sendEmailReserve(reserveData);
    const sendEmailRequestInfo = await sendEmailReserveInfo(reserveDataInfo);

    if (
      sendEmailRequest.status === 200 &&
      sendEmailRequestInfo.status === 200
    ) {
      localStorage.clear();
      setShowSuccessMessage(true);
    } else {
      setShowMessageError(true);
    }
  };

  const closeModalHandler = () => {
    setSelectedBoat("");
    setUserName("");
    setUserEmail("");
    setPhoneCode("");
    setUserPhone("");
    setDuration("");
    setPassengerNumber("");
    setStartTime("");
    setStartDate(null);
    setEndDate(null);
    setValueInputDate("");
    setShowMessageError(false);
    setShowSuccessMessage(false);

    closeModal();
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const countryCodeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPhoneCode(e.target.value);
  };

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );

      setUserPhone(formattedNumber);

      setCountryCode(codeCountry.countryCode);
    } else {
      setUserPhone(e);
      setUserPhone(e.replace(/\D/g, ""));
    }
  };

  const handleApplyClick = () => {
    let startedDateFormatted;
    let endDateFormatted;

    if (startDate) {
      startedDateFormatted = new Date(startDate)
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "/");
    }

    if (endDate) {
      endDateFormatted = new Date(endDate)
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "/");
    }

    let date: string | undefined = startedDateFormatted;

    if (!endDate || startedDateFormatted === endDateFormatted) {
      date = startedDateFormatted;
    } else {
      setDuration("");

      date = `${startedDateFormatted} until ${endDateFormatted}`;
    }

    setValueInputDate(date!);

    if (startDate && !endDate) {
      setEndDate(startDate);
    }

    setCalendarOpen(false);
  };

  return (
    <dialog
      id="contact-broker-modal"
      onCancel={closeModal}
      className={`${className ?? ""} ${
        showSuccessMessage || showMessageError ? "modal-message" : ""
      }`}
      ref={contactBrokerModalRef}
    >
      {showSuccessMessage ? (
        <>
          <div className="body-form">
            <div className="modal-form-finish-message">
              <img src={CircleCheckGreen} alt="circle check" />
              <h1 id="thank-you-modal" className="message-title">
                Booking successful!
              </h1>
              <p className="message-subtitle">
                We will contact you shortly with details on how to complete your
                reservation.
              </p>
            </div>
            <Button onClick={closeModalHandler} buttonStyle="secondary">
              Close
            </Button>
          </div>
        </>
      ) : showMessageError ? (
        <>
          <div className="body-form">
            <div className="modal-form-finish-message">
              <img src={CircleCrossRed} alt="circle error" />
              <h1 className="message-title">Oops!</h1>
              <p className="message-subtitle">
                Something went wrong, please try again.
              </p>
            </div>
            <div className="modal-form-finish-message__buttons-container">
              <Button
                onClick={() => setShowMessageError(false)}
                buttonStyle="primary"
              >
                Try Again
              </Button>
              <Button onClick={closeModalHandler} buttonStyle="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="contact-broker-modal__wrapper">
          <img
            src={CloseIcon}
            alt="close modal icon"
            onClick={closeModalHandler}
          />

          <div className="wrapper__header-texts">
            <p className="header-texts__title">Book this yacht</p>
            <p className="header-texts__subtitle">
              Get ready to enjoy your day on the water
            </p>
          </div>
          <div className="wrapper__form">
            <ListSelect
              labelSelect="Yacht"
              options={boatsOptions}
              onChange={setSelectedBoat}
              isWarning={selectedBoatWarning}
            />
            <InputText
              label="Name"
              value={userName}
              onChangeInput={setUserName}
              type="text"
              isWarning={userNameWarning}
            />

            <InputText
              label="Email"
              value={userEmail}
              onChangeInput={setUserEmail}
              type="email"
              isWarning={userEmailWarning}
            />
            <div className="grid-column-phone">
              <p className="grid-column-phone__label">Phone</p>
              <SimpleSelect
                options={codesMock.map((code) => {
                  return {
                    label: `${code.flag} ${code.code} ${code.countryCode}`,
                    value: JSON.stringify(code),
                  };
                })}
                onChange={countryCodeHandler}
                value={phoneCode}
                defaultValueText={"Select"}
                isWarning={phoneCodeWarning}
                className="grid-column-phone__select"
              />
              <InputText
                value={userPhone}
                onChangeInput={inputPhoneHandler}
                placeholder="Phone number"
                isWarning={userPhoneWarning}
                className="grid-column-phone__input"
              />
            </div>

            <InputText
              onChangeInput={() => {}}
              onClick={() => setCalendarOpen(true)}
              value={valueInputDate}
              readOnly
              placeholder="Pick a Date"
              isWarning={dateExpBeginWarning}
              inputStyle="input-date-picker"
              label="Date"
            />

            {calendarOpen && (
              <>
                <img
                  src={CloseIcon}
                  alt="X icon"
                  onClick={() => setCalendarOpen(false)}
                />
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  startDate={startDate as Date}
                  endDate={endDate as Date}
                  selectsRange
                  inline
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                  selectsDisabledDaysInRange={false}
                  // highlightDates={highlightDates}
                  // excludeDates={disabledDates}
                  showPreviousMonths={false}
                  monthsShown={widescreen >= 768 ? 2 : 12}
                >
                  <Button buttonStyle="primary" onClick={handleApplyClick}>
                    APPLY
                  </Button>
                </DatePicker>
              </>
            )}
            <div className="form__grid-template">
              <Select
                options={durationObject}
                labelSelect="Duration"
                onChange={setDuration}
                iconLeft={ClockIcon}
                isWarning={durationWarning}
                disabled={valueInputDate?.includes("until")}
                defaultValueText={duration}
              />
              <Select
                options={passengersObject}
                labelSelect="Passengers"
                iconLeft={PersonIcon}
                onChange={setPassengerNumber}
                isWarning={passengerNumberWarning}
                defaultValueText={passengerNumber}
              />
            </div>

            <Select
              options={startTimeObject}
              labelSelect="Start Time"
              onChange={setStartTime}
              iconLeft={ClockIcon}
              defaultValueText={startTime}
              // disabled={!ableTimes.length}
              isWarning={isStartTimeWarning}
            />
          </div>
          <div className="wrapper__buttons-container">
            <Button onClick={inputsVerification} buttonStyle="primary">
              Request
            </Button>
          </div>
          <div className="wrapper__footer">
            <img src={CircleCheckGreen} alt="circle check" />
            <h5 className="no-margin">Free Cancellation</h5>
            <p>Full refund 72hrs before booking date</p>
          </div>
          <div className="footer__last-text">
            <Hr className="last-text__hr" />
            <p className="last-text__disclaimer">You won't be charged yet.</p>
          </div>
        </div>
      )}
    </dialog>
  );
};

export default ContactBrokerModal;

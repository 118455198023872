import { ReactNode } from "react";
import Paragraph from "../Paragraph";
import Title from "../Title";
import "./styles.scss";

interface TitleParagraphProps {
  title: string;
  paragraph?: string;
  marginTop?: string | number;
  marginBottom?: string | number;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  className?: string;
  titleColor?: "white" | "black";
  paragraphColor?: "white" | "black" | "gray-dark" | "dust" | "gray";
  isTitleDetails?: boolean;
  isBannerTitle?: boolean;
}

const TitleParagraph: React.FC<TitleParagraphProps> = (props) => {
  const {
    paragraph,
    title,
    marginBottom,
    marginTop,
    textAlign,
    className,
    isTitleDetails,
    titleColor = "black",
    paragraphColor = "gray-dark",
    isBannerTitle,
  } = props;

  return (
    <div
      id="title-paragraph"
      className={className}
      style={{ marginTop, marginBottom, textAlign }}
    >
      <Title
        color={titleColor}
        isTitleDetails={isTitleDetails}
        isBannerTitle={isBannerTitle}
      >
        {title}
      </Title>
      {paragraph && <Paragraph color={paragraphColor} text={paragraph} />}
    </div>
  );
};

export default TitleParagraph;

//Nik Marin
import azimut70Nick from "../assets/images/brokers/NikMarin/70azimut_dolce/yacht-charte-miami_70-azimut-dolce_42.jpg";
import baia80Nick from "../assets/images/brokers/NikMarin/80baia_panther/yacht-charte-miami_80-baia-panther_33.jpg";
import azimut90Nick from "../assets/images/brokers/NikMarin/90azimut_daydreamin/yacht-charte-miami_90-azimut-daydreamin_27.jpg";
import azimut105Nick from "../assets/images/brokers/NikMarin/105azimut_daniela/yacht-charte-miami_105-azimut-daniela_4.jpg";
import azimut120Nick from "../assets/images/brokers/NikMarin/120azimut_rex/yacht-charte-miami_120-azimut-rex_2.jpg";
import pershing94Nick from "../assets/images/brokers/NikMarin/94persing_jerico/yacht-charte-miami_94-pershing-jerico_3.jpg";
//Chris
import azimut55Chris from "../assets/images/brokers/Chris/55Azimut/yacht-charte-miami_55-azimut_2.jpg";
//Adriana
import fairline55Adriana from "../assets/images/brokers/AdrianaJuhasz/55fairline_flybridge/yacht-charte-miami_55-fairline-flybridge_2.jpg";
import pershing65Adriana from "../assets/images/brokers/AdrianaJuhasz/65pershing_loki/yacht-charte-miami_65-pershing-loki_9.jpg";
import prestige60Adriana from "../assets/images/brokers/AdrianaJuhasz/60prestige/yacht-charte-miami_60-prestige_3.jpg";
import sunseeker75Adriana from "../assets/images/brokers/AdrianaJuhasz/75sunseeker/yacht-charte-miami_75-sunseeker_25.jpg";
//Phelipe
import nx400Phelipe from "../assets/images/nx400/yacht_charter_miami_nx40_04.png";
import { YachtBroker } from "../@types/yachtBrokers";

export const yachtsBrokers: YachtBroker[] = [
  {
    broker: "Nik Marin",
    routeName: "70-azimut-ladolcevita",
    name: "La Dolce Vita | Azimut 70'",
    image: azimut70Nick,
    make: "Azimut",
    model: "70’ Azimut",
    length: "70’",
    beam: "18’ 3",
    draft: "5’ 4",
    cruising_speed: "28–32 knots",
    passengers: 13,
    crew: "3-4",
    cabins: "3-4",
    base_price: "2,400",
    description:
      "A luxury-class Azimut yacht with modern design and premium comfort for long-range cruising.",
  },
  {
    broker: "Nik Marin",
    image: baia80Nick,
    name: "Panther | Baia 80'",
    routeName: "80-baia-panther",
    make: "Baia",
    model: "80’ Baia",
    length: "80’",
    beam: "19’ 6",
    draft: "5’ 6",
    cruising_speed: "30–35 knots",
    passengers: 13,
    crew: "3-4",
    cabins: "4-5",
    base_price: "2,800",
    description:
      "A luxury-class Baia yacht with modern design and premium comfort for long-range cruising.",
  },
  {
    broker: "Nik Marin",
    name: "Day Dreamin | Azimut 90'",
    routeName: "90-azimut-daydream",
    image: azimut90Nick,
    make: "Azimut",
    model: "90’ Azimut",
    length: "90’",
    beam: "21’ 7",
    draft: "5’ 11",
    cruising_speed: "24–28 knots",
    passengers: 13,
    crew: "4-5",
    cabins: "4-5",
    base_price: "$4,500",
    description:
      "A luxury-class Azimut yacht with modern design and premium comfort for long-range cruising.",
  },
  {
    broker: "Nik Marin",
    name: "Daniela | Azimut 105'",
    routeName: "105-azimut-daniela",
    image: azimut105Nick,
    make: "Azimut",
    model: "105’ Azimut",
    length: "105’",
    beam: "22’ 11",
    draft: "6’ 1",
    cruising_speed: "22–26 knots",
    passengers: 13,
    crew: "4-6",
    cabins: "5-6",
    base_price: "$5,500",
    description:
      "The Azimut 105 is a spacious and elegant superyacht, ideal for luxury charters and private cruises.",
  },
  {
    broker: "Nik Marin",
    image: azimut120Nick,
    name: "Rex | Azimut 120'",
    routeName: "120-azimut-rex",
    make: "Azimut",
    model: "120’ Azimut",
    length: "120’",
    beam: "24’ 5",
    draft: "6’ 5",
    cruising_speed: "20–24 knots",
    passengers: 13,
    crew: "5-7",
    cabins: "6-7",
    base_price: "$6,700",
    description:
      "A top-of-the-line Azimut yacht offering superior space, luxury, and high-end cruising capabilities.",
  },
  {
    broker: "Nik Marin",
    image: pershing94Nick,
    name: "Jerico | Pershing 94'",
    routeName: "94-pershing-jerico",
    make: "Pershing",
    model: "94' Pershing",
    length: "94'",
    beam: "20’ 5",
    draft: "5’ 7",
    cruising_speed: "38–42 knots",
    passengers: 13,
    crew: "3-5",
    cabins: "4-5",
    base_price: "$6,700",
    description:
      "The Pershing 94 'Jerico' is the epitome of luxury and speed, offering unparalleled performance with elegant Italian craftsmanship.",
  },
  // {
  //   broker: "Nik Marin",
  //   name: "Jerico",
  //   make: "Pershing",
  //   model: "94’ Pershing",
  //   length: "94’",
  //   beam: "20’ 5",
  //   draft: "5’ 7",
  //   cruising_speed: "38–42 knots",
  //   passengers: 13,
  //   crew: "3-5",
  //   cabins: "4-5",
  //   base_price: "$7,900",
  //   description:
  //     "The Pershing 94 'Jerico' is the epitome of luxury and speed, offering unparalleled performance with elegant Italian craftsmanship.",
  // },

  {
    broker: "Chris",
    routeName: "75-sunseeker",
    image: azimut55Chris,
    name: "Loki | Azimut 55'",
    make: "Azimut",
    model: "55’ Loki",
    length: "",
    beam: "",
    draft: "",
    cruising_speed: "",
    passengers: 0,
    crew: "",
    cabins: "",
    base_price: "1,300",
    description: "",
  },
  {
    broker: "Adriana Juhasz",
    image: pershing65Adriana,
    name: "Loki | Pershing 65'",
    routeName: "65-pershing-loki",
    make: "Pershing",
    model: "65’ Loki",
    length: "",
    beam: "",
    draft: "",
    cruising_speed: "",
    passengers: 0,
    crew: "",
    cabins: "",
    base_price: "",
    description: "",
  },
  {
    broker: "Adriana Juhasz",
    routeName: "55-fairline-flybridge",
    image: fairline55Adriana,
    name: "Catalaya | Flybridge 55'",
    make: "Fairline",
    model: "55’ Flybridge",
    length: "",
    beam: "",
    draft: "",
    cruising_speed: "",
    passengers: 0,
    crew: "",
    cabins: "",
    base_price: "",
    description: "",
  },
  {
    broker: "Adriana Juhasz",
    routeName: "60-prestige",
    image: prestige60Adriana,
    name: "La Dolce Vita II | Prestige 60'",
    make: "Prestige",
    model: "60’ Prestige",
    length: "",
    beam: "",
    draft: "",
    cruising_speed: "",
    passengers: 0,
    crew: "",
    cabins: "",
    base_price: "",
    description: "",
  },
  // {
  //   broker: "Adriana Juhasz",
  //   name: "Silverton 'Fantasy Island'",
  //   make: "Silverton",
  //   model: "50’ Silverton",
  //   length: "50’",
  //   beam: '15’ 4"',
  //   draft: '3’ 8"',
  //   cruising_speed: "20–24 knots",
  //   passengers: 13,
  //   crew: "2-3",
  //   cabins: "2-3",
  //   base_price: "$1,300",
  //   description:
  //     "A well-appointed flybridge yacht with modern styling and ample space for entertainment.",
  // },
  // {
  //   broker: "Adriana Juhasz",
  //   name: "Sea Ray Sport Sedan",
  //   make: "Sea Ray",
  //   model: "62’ Sea Ray Sport Sedan",
  //   length: "62’",
  //   beam: '16’ 9"',
  //   draft: '4’ 2"',
  //   cruising_speed: "22–26 knots",
  //   passengers: 13,
  //   crew: "3-4",
  //   cabins: "3-4",
  //   base_price: "$1,900",
  //   description:
  //     "The 62' Sport Sedan offers high-performance cruising with a spacious interior and elegant finish.",
  // },
  {
    broker: "Adriana Juhasz",
    name: "New Page | Sunseeker 75'",
    routeName: "75-sunseeker",
    image: sunseeker75Adriana,
    make: "Sunseeker",
    model: "75’ Sunseeker",
    length: "75’",
    beam: '18’ 10"',
    draft: '5’ 5"',
    cruising_speed: "24–28 knots",
    passengers: 13,
    crew: "3-4",
    cabins: "3-4",
    base_price: "",
    description:
      "Sunseeker's craftsmanship is on full display with this luxury cruiser, featuring top-notch amenities.",
  },
  {
    broker: "Phelipe Mestre",
    name: "Soul | NX Boats 40'",
    image: nx400Phelipe,
    routeName: "2022-nx-horizon-40",
    make: "NX Boats",
    model: "40’ NX 400 HT Horizon",
    length: "40’",
    beam: '11’ 15"',
    draft: "2’ 4",
    cruising_speed: "8 knots",
    passengers: 8,
    crew: "1",
    cabins: "-",
    base_price: "$1,200",
    description:
      "The NX 400 HT Horizon 2022 combines sleek, modern lines with a bold aesthetic, delivering both style and substance.",
  },
];
